import React from 'react'

import { Admin } from 'pages'

const Administrator: React.FC = () => {
  return (
    <Admin />
  )
}

export default Administrator
