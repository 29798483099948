import React from 'react'
import classnames from 'classnames'

interface Props {
  onChange: (checked: boolean) => void,
  checked?: boolean
  mixed?: boolean
}

const Checkbox: React.FC<Props> = ({ onChange, checked, mixed }) => {
  return (
    <div
      className={classnames(
        'loyal__app__checkbox', { checked, mixed }
      )}
      onClick={e => {
        e.stopPropagation()
        onChange(!checked)
      }}
    />
  )
}

export default Checkbox
