import { useState, useEffect } from 'react'

const useTicker = () => {
  const [date, setDate] = useState<Date>(new Date())

  useEffect(() => {
    const tick = () => {
      setDate(new Date())
    }

    let ticker = setInterval(() => tick(), 1000);
    return () => clearInterval(ticker)
  })

  return date
}

export default useTicker