import React, { ReactNode, CSSProperties } from 'react'
import classnames from 'classnames'
import { useDispatch } from 'react-redux'

import { active } from 'stores/effects/cursor'

interface Props {
  children: ReactNode
  size?: number
  color?: string
  show?: boolean
  delay?: string | number
  className?: string | string[]
  cursor?: boolean
  style?: CSSProperties
  split?: boolean
}

const Text: React.FC<Props> = ({
  children, className, style, show, split,
  size, color, delay, cursor = false
}) => {
  const letter = children?.toString().split('') || []

  const dispatch = useDispatch()

  const enter = () => {
    dispatch(active(cursor))
  }

  const leave = () => {
    dispatch(active(false))
  }

  const format = (text: string) => {
    if (text === ' ') return <span>&nbsp;</span>
    else if (text === '.') return <span>.</span>
    else return text
  }

  return (
    <div>
      <div
        className={classnames(
          'loyal__app__text', { show, cursor }
        )}
        style={{
          cursor: cursor ? 'pointer' : 'none',
        }}
        onMouseEnter={enter}
        onMouseMove={enter}
        onMouseLeave={leave}
        onClick={leave}
      >
        <div
          className="loyal__app__text__word"
          style={{ height: size }}
        >
          {split ?
            letter.map((item, index) =>
              <div
                key={index}
                className={classnames(
                  'loyal__app__text__letter', className
                )}
                style={{
                  ...style,
                  color,
                  animationDelay: `${index * Number(delay)}s`
                }}
              >
                {format(item)}
              </div>
            )
            :
            <div
              className={classnames(
                'loyal__app__text__letter', className
              )}
              style={{
                animationDelay: `${Number(delay)}s`
              }}
            >
              {children}
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default Text
