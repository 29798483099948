import React, { useState, useEffect, MouseEvent } from 'react'
import classnames from 'classnames'
import moment from 'moment'

import LoyalService from 'services/loyal.service'
import LoyalProjectService from 'services/loyal.project.service'
import { ContactUs, ProjectPlan } from 'types'

import { Image, Checkbox } from 'components'

interface Props {
  show?: boolean
}

const Admin: React.FC<Props> = ({ show }) => {
  const [load, setLoad] = useState<boolean>(false)
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [menu, setMenu] = useState<string>('inbox')
  const [tab, setTab] = useState<number>(0)
  /* Message */
  const [contact, setContact] = useState<ContactUs[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [message, setMessage] = useState<ContactUs>()
  const [bulkInbox, setBulkInbox] = useState<string[]>([])
  /* Project */
  const [project, setProject] = useState<ProjectPlan[]>([])
  const [openProject, setOpenProject] = useState<boolean>(false)
  const [messageProject, setMessageProject] = useState<ProjectPlan>()
  const [bulkInboxProject, setBulkInboxProject] = useState<string[]>([])
  /* Auth */
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const formatCurrency = (number: number) => {
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }

  const login = () => {
    setLoading(true)
    LoyalService.login(email, password).then(() => setLoading(false))
  }

  const logout = () => {
    LoyalService.logout()
  }

  useEffect(() => {
    LoyalService.authenticate((user: any) => {
      setUser(user)
      setLoad(true)
    })
  })

  useEffect(() => {
    const unsubscribe = LoyalProjectService.getAll().orderBy('created_at', 'desc').onSnapshot(dataProject)
    return () => unsubscribe()
  }, [])
  /* Auth */

  /* Message */
  const data = (items: any) => {
    const contacts: ContactUs[] = []
    items.docs.forEach((item: any) => {
      let data = item.data()
      contacts.push({
        id: item.id,
        created_at: new Date(data.created_at.seconds * 1000).toISOString(),
        deleted_at: data.deleted_at && new Date(data.deleted_at.seconds * 1000).toISOString(),
        name: data.name,
        email: data.email,
        subject: data.subject,
        message: data.message,
        read: data.read,
        starred: data.starred
      })
    })
    setContact(contacts)
  }

  const read = (item: ContactUs) => {
    setOpen(true)
    setMessage(item)
    if (!item.read && item.id)
      LoyalService.update(item.id, { read: true })
        .catch((e) => {
          console.log(e)
        })
  }

  const unread = (item: ContactUs) => {
    console.log(item)
    setOpen(true)
    setMessage(item)
    if (item.read && item.id)
      LoyalService.update(item.id, { read: false })
        .catch((e) => {
          console.log(e)
        })
  }

  const starred = (e: MouseEvent, item: ContactUs) => {
    e.stopPropagation()
    LoyalService.update(item.id as string, { starred: !item.starred })
      .catch((e) => {
        console.log(e)
      })
    item.starred = !item.starred
    setMessage(item)
  }

  const trash = (e: MouseEvent, id: string = '') => {
    e.stopPropagation()
    LoyalService.update(id, { deleted_at: new Date() })
      .catch((e) => {
        console.log(e)
      })
  }

  const remove = (e: MouseEvent, id: string = '') => {
    e.stopPropagation()
    LoyalService.remove(id)
      .catch((e) => {
        console.log(e)
      })
  }

  const readSelected = () => {
    messages.forEach(item => {
      const index = bulkInbox.indexOf(item.id as string)
      if (index !== -1 && !item.read) {
        LoyalService.update(item.id as string, { read: true })
          .catch((e) => {
            console.log(e)
          })
      }
    })
  }

  const unreadSelected = () => {
    messages.forEach(item => {
      const index = bulkInbox.indexOf(item.id as string)
      if (index !== -1 && item.read) {
        LoyalService.update(item.id as string, { read: false })
          .catch((e) => {
            console.log(e)
          })
      }
    })
  }

  const trashSelected = () => {
    messages.forEach(item => {
      const index = bulkInbox.indexOf(item.id as string)
      if (index !== -1) {
        bulkInbox.splice(index, 1)
        LoyalService.update(item.id as string, { deleted_at: new Date() })
          .catch((e) => {
            console.log(e)
          })
      }
    })
  }

  const removeSelected = () => {
    messages.forEach(item => {
      const index = bulkInbox.indexOf(item.id as string)
      if (index !== -1) {
        bulkInbox.splice(index, 1)
        LoyalService.remove(item.id as string)
          .catch((e) => {
            console.log(e)
          })
      }
    })
  }

  const moveSelected = () => {
    messages.forEach(item => {
      const index = bulkInbox.indexOf(item.id as string)
      if (index !== -1) {
        bulkInbox.splice(index, 1)
        LoyalService.update(item.id as string, { deleted_at: null })
          .catch((e) => {
            console.log(e)
          })
      }
    })
  }

  const messages = contact.filter(item => menu === 'inbox' ? !item.deleted_at : menu === 'starred' ? item.starred && !item.deleted_at : item.deleted_at)

  const selected = (id: string) => {
    const index = bulkInbox.indexOf(id)
    if (index !== -1)
      bulkInbox.splice(index, 1)
    else
      bulkInbox.push(id)
    setBulkInbox([...bulkInbox])
  }

  const selectedAll = (checked: boolean) => {
    messages.forEach(item => {
      const index = bulkInbox.indexOf(item.id as string)
      if (checked && index === -1) bulkInbox.push(item.id as string)
      if (!checked && index !== -1) bulkInbox.splice(index, 1)
    })
    setBulkInbox([...bulkInbox])
  }

  let checkedAll = messages.length > 0
  messages.forEach(item => {
    const index = bulkInbox.indexOf(item.id as string)
    if (index === -1) checkedAll = false
  })

  let selectMessage = false
  messages.forEach(item => {
    const index = bulkInbox.indexOf(item.id as string)
    if (index !== -1) selectMessage = true
  })

  let selectReadMessage = false
  messages.forEach(item => {
    const index = bulkInbox.indexOf(item.id as string)
    if (index !== -1 && !item.read) selectReadMessage = true
  })

  let selectUnreadMessage = false
  messages.forEach(item => {
    const index = bulkInbox.indexOf(item.id as string)
    if (index !== -1 && item.read) selectUnreadMessage = true
  })

  useEffect(() => {
    const unsubscribe = LoyalService.getAll().orderBy('created_at', 'desc').onSnapshot(data)
    return () => unsubscribe()
  }, [])
  /* Message */

  /* Project */
  const projects = project.filter(item => menu === 'inbox' ? !item.deleted_at : menu === 'starred' ? item.starred && !item.deleted_at : item.deleted_at)

  const selectedProject = (id: string) => {
    const index = bulkInboxProject.indexOf(id)
    if (index !== -1)
      bulkInboxProject.splice(index, 1)
    else
      bulkInboxProject.push(id)
    setBulkInboxProject([...bulkInboxProject])
  }

  const selectedAllProject = (checked: boolean) => {
    projects.forEach(item => {
      const index = bulkInboxProject.indexOf(item.id as string)
      if (checked && index === -1) bulkInboxProject.push(item.id as string)
      if (!checked && index !== -1) bulkInboxProject.splice(index, 1)
    })
    setBulkInbox([...bulkInboxProject])
  }

  let checkedAllProject = projects.length > 0
  projects.forEach(item => {
    const index = bulkInboxProject.indexOf(item.id as string)
    if (index === -1) checkedAllProject = false
  })

  let selectMessageProject = false
  projects.forEach(item => {
    const index = bulkInboxProject.indexOf(item.id as string)
    if (index !== -1) selectMessageProject = true
  })

  let selectReadMessageProject = false
  projects.forEach(item => {
    const index = bulkInboxProject.indexOf(item.id as string)
    if (index !== -1 && !item.read) selectReadMessageProject = true
  })

  let selectUnreadMessageProject = false
  projects.forEach(item => {
    const index = bulkInboxProject.indexOf(item.id as string)
    if (index !== -1 && item.read) selectUnreadMessageProject = true
  })

  const dataProject = (items: any) => {
    const projects: ProjectPlan[] = []
    items.docs.forEach((item: any) => {
      let data = item.data()
      projects.push({
        id: item.id,
        created_at: new Date(data.created_at.seconds * 1000).toISOString(),
        deleted_at: data.deleted_at && new Date(data.deleted_at.seconds * 1000).toISOString(),
        name: data.name,
        email: data.email,
        company: data.company,
        type_project: data.type_project,
        description_project: data.description_project,
        budget_range: data.budget_range,
        timeline_project: data.timeline_project,
        source: data.source,
        read: data.read,
        starred: data.starred
      })
    })
    setProject(projects)
  }

  const readProject = (item: ProjectPlan) => {
    setOpenProject(true)
    setMessageProject(item)
    if (!item.read && item.id)
      LoyalProjectService.update(item.id, { read: true })
        .catch((e) => {
          console.log(e)
        })
  }

  const unreadProject = (item: ProjectPlan) => {
    console.log(item)
    setOpenProject(true)
    setMessageProject(item)
    if (item.read && item.id)
      LoyalProjectService.update(item.id, { read: false })
        .catch((e) => {
          console.log(e)
        })
  }

  const starredProject = (e: MouseEvent, item: ProjectPlan) => {
    e.stopPropagation()
    LoyalProjectService.update(item.id as string, { starred: !item.starred })
      .catch((e) => {
        console.log(e)
      })
    item.starred = !item.starred
    setMessageProject(item)
  }

  const trashProject = (e: MouseEvent, id: string = '') => {
    e.stopPropagation()
    LoyalProjectService.update(id, { deleted_at: new Date() })
      .catch((e) => {
        console.log(e)
      })
  }

  const removeProject = (e: MouseEvent, id: string = '') => {
    e.stopPropagation()
    LoyalProjectService.remove(id)
      .catch((e) => {
        console.log(e)
      })
  }

  const readSelectedProject = () => {
    projects.forEach(item => {
      const index = bulkInboxProject.indexOf(item.id as string)
      if (index !== -1 && !item.read) {
        LoyalProjectService.update(item.id as string, { read: true })
          .catch((e) => {
            console.log(e)
          })
      }
    })
  }

  const unreadSelectedProject = () => {
    projects.forEach(item => {
      const index = bulkInboxProject.indexOf(item.id as string)
      if (index !== -1 && item.read) {
        LoyalProjectService.update(item.id as string, { read: false })
          .catch((e) => {
            console.log(e)
          })
      }
    })
  }

  const trashSelectedProject = () => {
    projects.forEach(item => {
      const index = bulkInboxProject.indexOf(item.id as string)
      if (index !== -1) {
        bulkInboxProject.splice(index, 1)
        LoyalProjectService.update(item.id as string, { deleted_at: new Date() })
          .catch((e) => {
            console.log(e)
          })
      }
    })
  }

  const removeSelectedProject = () => {
    projects.forEach(item => {
      const index = bulkInboxProject.indexOf(item.id as string)
      if (index !== -1) {
        bulkInboxProject.splice(index, 1)
        LoyalProjectService.remove(item.id as string)
          .catch((e) => {
            console.log(e)
          })
      }
    })
  }

  const moveSelectedProject = () => {
    projects.forEach(item => {
      const index = bulkInboxProject.indexOf(item.id as string)
      if (index !== -1) {
        bulkInboxProject.splice(index, 1)
        LoyalProjectService.update(item.id as string, { deleted_at: null })
          .catch((e) => {
            console.log(e)
          })
      }
    })
  }
  /* Project */

  const notification = contact?.filter(item => !item.read && !item.deleted_at)?.length
  const starred__notification = contact?.filter(item => item.starred && !item.deleted_at)?.length
  const trash__notification = contact?.filter(item => item.deleted_at)?.length

  const notification__project = project?.filter(item => !item.read && !item.deleted_at)?.length
  const starred__notification__project = project?.filter(item => item.starred && !item.deleted_at)?.length
  const trash__notification__project = project?.filter(item => item.deleted_at)?.length

  return (
    <div className="loyal__app__admin">
      {user ?
        <div>
          <div className="loyal__app__admin__messages">
            <div className="loyal__app__admin__left__panel">
              <div
                className={classnames(
                  'loyal__app__admin__left__panel__menu', { 'active': menu === 'inbox' }
                )}
                onClick={() => {
                  setMenu('inbox')
                  setOpen(false)
                  setOpenProject(false)
                }}
              >
                <div className="icon">
                  <Image
                    src="inbox.svg"
                    path="icons"
                    height="15"
                  />
                </div>
                <span>Inbox</span>
                {notification + notification__project > 0 &&
                  <div className="loyal__app__admin__left__panel__notification active">
                    {notification + notification__project}
                  </div>
                }
              </div>
              <div
                className={classnames(
                  'loyal__app__admin__left__panel__menu', { 'active': menu === 'starred' }
                )}
                onClick={() => {
                  setMenu('starred')
                  setOpen(false)
                  setOpenProject(false)
                }}
              >
                <div className="icon">
                  <Image
                    src="star.svg"
                    path="icons"
                    height="15"
                  />
                </div>
                <span>Starred</span>
                {starred__notification + starred__notification__project > 0 &&
                  <div className="loyal__app__admin__left__panel__notification">
                    {starred__notification + starred__notification__project}
                  </div>
                }
              </div>
              <div
                className={classnames(
                  'loyal__app__admin__left__panel__menu', { 'active': menu === 'trash' }
                )}
                onClick={() => {
                  setMenu('trash')
                  setOpen(false)
                  setOpenProject(false)
                }}
              >
                <div className="icon">
                  <Image
                    src="trash.svg"
                    path="icons"
                    height="15"
                  />
                </div>
                <span>Trash</span>
                {trash__notification + trash__notification > 0 &&
                  <div className="loyal__app__admin__left__panel__notification">
                    {trash__notification + trash__notification}
                  </div>
                }
              </div>
              <div
                className={classnames(
                  'loyal__app__admin__left__panel__menu'
                )}
              >
                <div
                  className="loyal__app__admin__action__button"
                  onClick={logout}
                >
                  Logout
                </div>
              </div>
            </div>
            <div className="loyal__app__admin__right__panel">
              {open || openProject ?
                <>
                  {/* Message */}
                  {open &&
                    <>
                      <div className="loyal__app__admin__action__bar">
                        <div
                          className="loyal__app__admin__action__button button__back"
                          onClick={() => setOpen(false)}
                        >
                          Back
                        </div>
                        <div
                          className="loyal__app__admin__action__button"
                          onClick={e => {
                            remove(e, message?.id)
                            setOpen(false)
                          }}
                        >
                          Delete
                        </div>
                        {message?.read &&
                          <div
                            className="loyal__app__admin__action__button"
                            onClick={() => {
                              unread(message as ContactUs)
                              setOpen(false)
                            }}
                          >
                            Mark as unread
                          </div>
                        }
                        <div
                          className="loyal__app__admin__action__button"
                          onClick={e => starred(e, message as ContactUs)}
                        >
                          {message?.starred ? 'Remove' : 'Add'} star
                        </div>
                      </div>
                      <div className="loyal__app__admin__message__content">
                        <div className="top">
                          <div className="subject">
                            <Image
                              src="email-read.svg"
                              path="icons"
                              height="15"
                            />
                            <span>{message?.subject}</span>
                          </div>
                          <div className="sender">
                            <a
                              href={`mailto:${message?.email}?subject=Reply:${message?.subject}&body=%0D%0A%0D%0A| On ${moment(message?.created_at).format('DD MMM YYYY, hh:mm a')} ${message?.name} <${message?.email}> wrote:%0D%0A${message?.message}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span>{message?.name}</span> ({message?.email})
                            </a>
                          </div>
                        </div>
                        <div className="message">
                          {message?.message}
                        </div>
                      </div>
                    </>
                  }
                  {/* Message */}

                  {/* Product */}
                  {openProject &&
                    <>
                      <div className="loyal__app__admin__action__bar">
                        <div
                          className="loyal__app__admin__action__button button__back"
                          onClick={() => setOpenProject(false)}
                        >
                          Back
                        </div>
                        <div
                          className="loyal__app__admin__action__button"
                          onClick={e => {
                            removeProject(e, messageProject?.id)
                            setOpenProject(false)
                          }}
                        >
                          Delete
                        </div>
                        {messageProject?.read &&
                          <div
                            className="loyal__app__admin__action__button"
                            onClick={() => {
                              unreadProject(messageProject as ProjectPlan)
                              setOpenProject(false)
                            }}
                          >
                            Mark as unread
                          </div>
                        }
                        <div
                          className="loyal__app__admin__action__button"
                          onClick={e => starredProject(e, messageProject as ProjectPlan)}
                        >
                          {messageProject?.starred ? 'Remove' : 'Add'} star
                        </div>
                      </div>
                      <div className="loyal__app__admin__message__content">
                        <div className="top">
                          <div className="company">
                            <Image
                              src="company.svg"
                              path="icons"
                              height="30"
                            />
                            <span>{messageProject?.company}</span>
                          </div>
                          <div className="sender">
                            <a
                              href={`mailto:${messageProject?.email}?body=%0D%0A%0D%0A| On ${moment(messageProject?.created_at).format('DD MMM YYYY, hh:mm a')} ${messageProject?.name} <${messageProject?.email}> wrote:%0D%0A${messageProject?.description_project}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span>{messageProject?.name}</span> ({messageProject?.email})
                            </a>
                          </div>
                        </div>
                        <div className="infobar">
                          <div>
                            <div className="title">Project</div>
                            <div className="badge">
                              {messageProject?.type_project?.map(item => <span>{item}</span>)}
                            </div>
                          </div>
                          <div>
                            <div className="title">Budget</div>
                            <div className="budget">
                              <div>
                                <span>From</span>
                                <div>
                                  {formatCurrency(messageProject?.budget_range?.[0] || 0)}.000
                                </div>
                              </div>
                              <div>
                                <span>&nbsp;to&nbsp;</span>
                                <div>
                                  {formatCurrency(messageProject?.budget_range?.[1] || 0)}.000 IDR
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="message">
                          {messageProject?.description_project}
                        </div>
                      </div>
                    </>
                  }
                  {/* Product */}
                </>
                :
                <div>
                  <div className="loyal__app__admin__tabs">
                    <div
                      className={classnames(
                        'loyal__app__admin__tab', { 'active': tab === 0 }
                      )}
                      onClick={() => setTab(0)}
                    >
                      Messages
                      {menu === 'inbox' && notification > 0 &&
                        <div className="notification">
                          {notification}
                        </div>
                      }
                      {menu === 'starred' && starred__notification > 0 &&
                        <div className="notification starred">
                          {starred__notification}
                        </div>
                      }
                      {menu === 'trash' && trash__notification > 0 &&
                        <div className="notification trash">
                          {trash__notification}
                        </div>
                      }
                    </div>
                    <div
                      className={classnames(
                        'loyal__app__admin__tab', { 'active': tab === 1 }
                      )}
                      onClick={() => setTab(1)}
                    >
                      Projects
                      {menu === 'inbox' && notification__project > 0 &&
                        <div className="notification">
                          {notification__project}
                        </div>
                      }
                      {menu === 'starred' && starred__notification__project > 0 &&
                        <div className="notification starred">
                          {starred__notification__project}
                        </div>
                      }
                      {menu === 'trash' && trash__notification__project > 0 &&
                        <div className="notification trash">
                          {trash__notification__project}
                        </div>
                      }
                    </div>
                  </div>
                  {tab === 0 &&
                    <>
                      <div className="loyal__app__admin__action__bar">
                        <Checkbox
                          checked={checkedAll}
                          mixed={!checkedAll && selectMessage}
                          onChange={selectedAll}
                        />
                        {(menu === 'inbox' || menu === 'starred') &&
                          <>
                            <div
                              className={classnames(
                                'loyal__app__admin__action__button', { 'disabled': !selectMessage }
                              )}
                              onClick={() => selectMessage && trashSelected()}
                            >
                              Delete
                            </div>
                            <div
                              className={classnames(
                                'loyal__app__admin__action__button', { 'disabled': !selectReadMessage }
                              )}
                              onClick={() => selectReadMessage && readSelected()}
                            >
                              Mark all as read
                            </div>
                            <div
                              className={classnames(
                                'loyal__app__admin__action__button', { 'disabled': !selectUnreadMessage }
                              )}
                              onClick={() => selectUnreadMessage && unreadSelected()}
                            >
                              Mark all as unread
                            </div>
                          </>
                        }
                        {menu === 'trash' &&
                          <>
                            <div
                              className={classnames(
                                'loyal__app__admin__action__button', { 'disabled': !selectMessage }
                              )}
                              onClick={() => selectMessage && removeSelected()}
                            >
                              Delete forever
                            </div>
                            <div
                              className={classnames(
                                'loyal__app__admin__action__button', { 'disabled': !selectMessage }
                              )}
                              onClick={() => selectMessage && moveSelected()}
                            >
                              Restore
                            </div>
                          </>
                        }
                      </div>
                      <table
                        className="loyal__app__admin__table"
                        cellPadding="0"
                        cellSpacing="0"
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Subject</th>
                            <th>Message</th>
                            <th>Date</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {messages.map((item, index) =>
                            <tr
                              key={index}
                              className={classnames(
                                {
                                  'unread': !item.read,
                                  'read': item.read
                                }
                              )}
                              onClick={() => read(item)}
                            >
                              <td>
                                <div>
                                  <Checkbox
                                    checked={Boolean(bulkInbox.find(id => id === item.id))}
                                    onChange={() => selected(item.id as string)}
                                  />
                                  {item.starred && menu !== 'trash' ?
                                    <div
                                      className="star__active"
                                      onClick={e => starred(e, item)}
                                    >
                                      <Image
                                        src="star-fill.svg"
                                        path="icons"
                                        height="15"
                                      />
                                    </div>
                                    :
                                    menu !== 'trash' &&
                                    <div
                                      className="star"
                                      onClick={e => starred(e, item)}
                                    >
                                      <Image
                                        src="star.svg"
                                        path="icons"
                                        height="15"
                                      />
                                    </div>
                                  }
                                  {menu === 'trash' &&
                                    <Image
                                      src="trash.svg"
                                      path="icons"
                                      height="15"
                                    />
                                  }
                                  <span>{item.name}</span>
                                </div>
                              </td>
                              <td><span>{item.email}</span></td>
                              <td><span>{item.subject}</span></td>
                              <td><span>{item.message}</span></td>
                              <td>
                                <div>
                                  <Image
                                    src={item.read ? 'email-read.svg' : 'email-unread.svg'}
                                    path="icons"
                                    height="15"
                                  />
                                  <span>{moment(item.created_at).format('DD MMM YYYY, hh:mm a')}</span>
                                </div>
                              </td>
                              <td>
                                {menu === 'trash' ?
                                  <div
                                    className="loyal__app__admin__delete__forever"
                                    onClick={e => remove(e, item.id)}
                                  >
                                    Delete forever
                                  </div>
                                  :
                                  <div
                                    onClick={e => trash(e, item.id)}
                                  >
                                    <Image
                                      src="trash.svg"
                                      path="icons"
                                      height="15"
                                    />
                                  </div>
                                }
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </>
                  }

                  {/* Project */}
                  {tab === 1 &&
                    <>
                      <div className="loyal__app__admin__action__bar">
                        <Checkbox
                          checked={checkedAllProject}
                          mixed={!checkedAllProject && selectMessageProject}
                          onChange={selectedAllProject}
                        />
                        {(menu === 'inbox' || menu === 'starred') &&
                          <>
                            <div
                              className={classnames(
                                'loyal__app__admin__action__button', { 'disabled': !selectMessageProject }
                              )}
                              onClick={() => selectMessageProject && trashSelectedProject()}
                            >
                              Delete
                            </div>
                            <div
                              className={classnames(
                                'loyal__app__admin__action__button', { 'disabled': !selectReadMessageProject }
                              )}
                              onClick={() => selectReadMessageProject && readSelectedProject()}
                            >
                              Mark all as read
                            </div>
                            <div
                              className={classnames(
                                'loyal__app__admin__action__button', { 'disabled': !selectUnreadMessageProject }
                              )}
                              onClick={() => selectUnreadMessageProject && unreadSelectedProject()}
                            >
                              Mark all as unread
                            </div>
                          </>
                        }
                        {menu === 'trash' &&
                          <>
                            <div
                              className={classnames(
                                'loyal__app__admin__action__button', { 'disabled': !selectMessageProject }
                              )}
                              onClick={() => selectMessageProject && removeSelectedProject()}
                            >
                              Delete forever
                            </div>
                            <div
                              className={classnames(
                                'loyal__app__admin__action__button', { 'disabled': !selectMessageProject }
                              )}
                              onClick={() => selectMessageProject && moveSelectedProject()}
                            >
                              Restore
                            </div>
                          </>
                        }
                      </div>
                      <table
                        className="loyal__app__admin__table"
                        cellPadding="0"
                        cellSpacing="0"
                      >
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Company</th>
                            <th>Services</th>
                            <th>Description</th>
                            <th>Budget</th>
                            <th>Source</th>
                            <th>Date</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {projects.map((item, index) =>
                            <tr
                              key={index}
                              className={classnames(
                                {
                                  'unread': !item.read,
                                  'read': item.read
                                }
                              )}
                              onClick={() => readProject(item)}
                            >
                              <td>
                                <div>
                                  <Checkbox
                                    checked={Boolean(bulkInboxProject.find(id => id === item.id))}
                                    onChange={() => selectedProject(item.id as string)}
                                  />
                                  {item.starred && menu !== 'trash' ?
                                    <div
                                      className="star__active"
                                      onClick={e => starredProject(e, item)}
                                    >
                                      <Image
                                        src="star-fill.svg"
                                        path="icons"
                                        height="15"
                                      />
                                    </div>
                                    :
                                    menu !== 'trash' &&
                                    <div
                                      className="star"
                                      onClick={e => starredProject(e, item)}
                                    >
                                      <Image
                                        src="star.svg"
                                        path="icons"
                                        height="15"
                                      />
                                    </div>
                                  }
                                  {menu === 'trash' &&
                                    <Image
                                      src="trash.svg"
                                      path="icons"
                                      height="15"
                                    />
                                  }
                                  <span>{item.name}</span>
                                </div>
                              </td>
                              <td><span>{item.email}</span></td>
                              <td><span>{item.company}</span></td>
                              <td><span>{item.type_project?.join(', ')}</span></td>
                              <td><span>{item.description_project}</span></td>
                              <td>
                                {item.budget_range &&
                                  <>
                                    <div>
                                      IDR {formatCurrency(item.budget_range?.[0] || 0)}K
                                    </div>
                                    <div>&nbsp;to&nbsp;</div>
                                    <div>
                                      IDR {formatCurrency(item.budget_range?.[1] || 0)}K
                                    </div>
                                  </>
                                }
                              </td>
                              <td><span>{item.source}</span></td>
                              <td>
                                <div>
                                  <Image
                                    src={item.read ? 'email-read.svg' : 'email-unread.svg'}
                                    path="icons"
                                    height="15"
                                  />
                                  <span>{moment(item.created_at).format('DD MMM YYYY, hh:mm a')}</span>
                                </div>
                              </td>
                              <td>
                                {menu === 'trash' ?
                                  <div
                                    className="loyal__app__admin__delete__forever"
                                    onClick={e => removeProject(e, item.id)}
                                  >
                                    Delete forever
                                  </div>
                                  :
                                  <div
                                    onClick={e => trashProject(e, item.id)}
                                  >
                                    <Image
                                      src="trash.svg"
                                      path="icons"
                                      height="15"
                                    />
                                  </div>
                                }
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </>
                  }
                  {/* Project */}
                </div>
              }
            </div>
          </div>
        </div>
        :
        load &&
        <div className="loyal__app__admin__login__box">
          <Image src="loyalid-blue.svg" path="logo" height={40} />
          <div>
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          <div
            className="loyal__app__admin__action__button"
            onClick={login}
          >
            {loading ? 'Loading...' : 'Login'}
          </div>
        </div>
      }
    </div >
  )
}

export default Admin
