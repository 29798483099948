import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { State } from 'stores'
import { setPageTo } from 'stores/effects/page'

import { Content, Button, Text, Clock } from 'components'
import { pages } from 'pages'

const Work: React.FC = () => {
  const page = useSelector((state: State) => state.page).page
  const dispatch = useDispatch()
  const history = useHistory()

  const next = () => {
    dispatch(setPageTo(page as number + 1))
    history.push('/get-started')
  }

  const active = page === pages.indexOf('/our-work') + 1

  return (
    <Content>
      <div className="loyal__app__work">
        <div className="loyal__app__work__plan">
          <div className="loyal__app__work__plan__container">
            <div className="loyal__app__work__plan__content">
              <Clock />
              <Text
                show={active}
              >
                Have A Vision?
              </Text>
              <div
                className="plan"
                onClick={next}
              >
                <Text
                  show={active}
                  split
                  cursor
                  delay=".04"
                >
                  Plan A Project.
                </Text>
              </div>
            </div>
          </div>
          <div className="loyal__app__work__plan__side" />
          <Button onClick={next} />
        </div>
      </div>
    </Content>
  )
}

export default Work
