import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'

let config = {
  apiKey: "AIzaSyDFEb309LYFICVHOXYR6WfWj5LRRQM7cLw",
  authDomain: "loyal-firebase.firebaseapp.com",
  projectId: "loyal-firebase",
  storageBucket: "loyal-firebase.appspot.com",
  messagingSenderId: "970453781382",
  appId: "1:970453781382:web:7b02e7045a93f2a83d7a07",
  measurementId: "G-RFBW8SHK48"
};

firebase.initializeApp(config)
firebase.analytics()

export const auth = firebase.auth()
export const firestore = firebase.firestore()
