import React from 'react'
import classnames from 'classnames'
import GoogleMapReact from 'google-map-react'
import { useHistory } from 'react-router'

import { useDispatch, useSelector } from 'react-redux'

import { State } from 'stores'
import { setPageTo, setMenu } from 'stores/effects/page'
import { active } from 'stores/effects/cursor'

import { Text, Image, Button } from 'components'
import { pages } from 'pages'

interface PLaceProps {
  lat: number
  lng: number
}

const Menu: React.FC = () => {
  const open = useSelector((state: State) => state.page).menu

  const dispatch = useDispatch()
  const history = useHistory()

  const click = (page: number) => {
    dispatch(setPageTo(page))
    dispatch(setMenu(false))
    dispatch(active(false))
    if (page === 6)
      history.push('/contact-us')
    else
      history.push(pages[page - 1])
  }

  const Place = (data: PLaceProps) => (
    <div className="loyal__app__map__point">
      <Image src="loyalid-blue.svg" path="logo" height="20" />
      <span>{data.lat},{data.lng}</span>
    </div>
  )

  const config = {
    key: '',
    center: {
      lat: -6.153762641648894,
      lng: 106.89315350176865
    },
    zoom: 15
  }

  return (
    <div
      className={classnames('loyal__app__menu', { open })}
    >
      <div className="loyal__app__menu__content">
        <div className="loyal__app__menu__link">
          <div
            className="loyal__app__menu__label"
            onClick={() => click(1)}
          >
            <Text
              show={open}
              cursor
              split
              delay=".05"
            >
              Intro.
            </Text>
          </div>
          <div
            className="loyal__app__menu__label"
            onClick={() => click(2)}
          >
            <Text
              show={open}
              delay=".05"
              cursor
              split
            >
              About.
            </Text>
          </div>
          <div
            className="loyal__app__menu__label"
            onClick={() => click(3)}
          >
            <Text
              show={open}
              delay=".05"
              cursor
              split
            >
              Services.
            </Text>
          </div>
          <div
            className="loyal__app__menu__label"
            onClick={() => click(4)}
          >
            <Text
              show={open}
              delay=".05"
              cursor
              split
            >
              Products.
            </Text>
          </div>
          <div
            className="loyal__app__menu__label"
            onClick={() => click(6)}
          >
            <Text
              show={open}
              delay=".05"
              cursor
              split
            >
              Contact.
            </Text>
          </div>
        </div>
        <div className="company">
          {open &&
            <div>
              <div className="info">
                <h2>PT LOYAL MEMBER INDONESIA</h2>
                <div>Address: Ruko Inkopal Blok B No 9</div>
              </div>
              <div className="map">
                {config.key ?
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: config.key }}
                    defaultCenter={config.center}
                    defaultZoom={config.zoom}
                  >
                    <Place
                      lat={config.center.lat}
                      lng={config.center.lng}
                    />
                  </GoogleMapReact>
                  :
                  <iframe
                    title="map"
                    style={{ height: '60vh', width: '100%' }}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d249.30738690164577!2d106.89306614344343!3d-6.153766257225686!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNsKwMDknMTMuNiJTIDEwNsKwNTMnMzUuNCJF!5e0!3m2!1sen!2sid!4v1627973486689!5m2!1sen!2sid"
                    frameBorder="0"
                  />
                }
              </div>
            </div>
          }
        </div>
      </div>
      {open &&
        <div className="loyal__app__login__button">
          <Button
            onClick={() => history.push('/admin')}
            label={['LOGIN']}
          />
        </div>
      }
    </div>
  )
}

export default Menu
