import React from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { State } from 'stores'
import { setPageTo } from 'stores/effects/page'

import { Content, Text, Button, Image } from 'components'
import { pages } from 'pages'

const Intro: React.FC = () => {
  const dispatch = useDispatch()
  const state = useSelector((state: State) => state.page)
  const page = state.page
  const pageTo = state.pageTo
  const active = page === 1
  const size = window.innerWidth * (80 / 100)

  const history = useHistory()

  return (
    <Content
      active={active}
      background="linear-gradient(45deg, #003079, #951bae)"
    >
      <div className="loyal__app__intro">
        <div className="loyal__app__intro__left__panel">
          <div className="loyal__app__intro__header">
            <Text color="white" show={active}>
              MOST
            </Text>
            <Text color="white" delay=".2" show={active}>
              COMPLETE
            </Text>
            <Text color="white" delay=".4" show={active}>
              LOYALTY
            </Text>
            <Text color="white" delay=".6" show={active}>
              PROGRAM<span>.</span>
            </Text>
          </div>
          <div className="loyal__app__intro__word">
            <Text color="white" delay=".8" show={active}>
              We believe for every business customer is the king,
            </Text>
            <Text color="white" delay="1" show={active}>
              that’s why you need an CRM solution that automates
            </Text>
            <Text color="white" delay="1.2" show={active}>
              your core customer membership,
            </Text>
            <Text color="white" delay="1.4" show={active}>
              customer service and marketing processes.
            </Text>
          </div>
          <div className="loyal__app__intro__image__mobile">
            <Image src="image-1.png" width={size} />
          </div>
          <div className="loyal__app__intro__button">
            <Button
              onClick={() => {
                dispatch(setPageTo(2))
                history.push(pages[1])
              }}
            />
          </div>
        </div>
        <div
          className={
            classnames(
              'loyal__app__intro__right__panel', { active }
            )
          }>
          <div
            className={
              classnames(
                'loyal__app__intro__right__panel__inner',
                {
                  'skew': Number(pageTo) > 1
                }
              )
            }
          />
        </div>
      </div>
    </Content>
  )
}

export default Intro
