import React from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import { State } from 'stores'
import { Content, Image } from 'components'

const Products: React.FC = () => {
  const page = useSelector((state: State) => state.page).page
  const active = page === 4
  return (
    <Content
      active={active}
      background="#25054d"
    >
      <div className="loyal__app__products">
        <div
          className={classnames(
            'title',
            {
              'show': active
            }
          )}
        >
          <div>
            <h1>Products<span>.</span></h1>
          </div>
          <div>
            <p>From online tools and training to best practice coaching, 24/7 support, technical help for app development, and Administration Services to extend your team’s capacity, Success Plans give you everything you need to make sure you’re getting the most out of your Loyalid investment.</p>
          </div>
        </div>
        <div className={
          classnames(
            'content',
            {
              'show': active
            }
          )}
        >
          <div
            style={{
              animationDelay: '1s'
            }}
          >
            <div className="icon">
              <Image
                src="integrated-database.png"
                height="200"
              />
            </div>
            <span>INTEGRATED MULTI PROPERTY DATABASE</span>
          </div>
          <div
            style={{
              animationDelay: '1.2s'
            }}
          >
            <div className="icon">
              <Image
                src="multitier-membership.png"
                height="200"
              />
            </div>
            <span>MULTI TIER MEMBERSHIP</span>
          </div>
          <div
            style={{
              animationDelay: '1.4s'
            }}
          >
            <div className="icon">
              <Image
                src="multi-point.png"
                height="200"
              />
            </div>
            <span>MULTI POINTS TYPE</span>
          </div>
          <div
            style={{
              animationDelay: '1.6s'
            }}
          >
            <div className="icon">
              <Image
                src="point-formula.png"
                height="200"
              />
            </div>
            <span>POINT FORMULA</span>
          </div>
          <div
            style={{
              animationDelay: '1.8s'
            }}
          >
            <div className="icon">
              <Image
                src="point-exchange.png"
                height="200"
              />
            </div>
            <span>POINT EXCHANGE</span>
          </div>
          <div
            style={{
              animationDelay: '2s'
            }}
          >
            <div className="icon">
              <Image
                src="realtime-report.png"
                height="200"
              />
            </div>
            <span>REAL TIME REPORTING</span>
          </div>
          <div
            style={{
              animationDelay: '2.2s'
            }}
          >
            <div className="icon">
              <Image
                src="web-apps.png"
                height="200"
              />
            </div>
            <span>WEB APPLICATION</span>
          </div>
          <div
            style={{
              animationDelay: '2.4s'
            }}
          >
            <div className="icon">
              <Image
                src="mobile-apps.png"
                height="200"
              />
            </div>
            <span>MOBILE APPLICATION</span>
          </div>
        </div>
      </div>
    </Content>
  )
}

export default Products
