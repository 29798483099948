import React, { ReactNode, CSSProperties } from 'react'
import classnames from 'classnames'

interface Props {
  active?: boolean
  children?: ReactNode | ReactNode[]
  background?: string
  style?: CSSProperties
  className?: string | string[]
}

const Content: React.FC<Props> = ({
  active, children, background, style, className
}) => {
  return (
    <div
      className={classnames(
        'loyal__app__content', { className, active }
      )}
      style={{
        ...style,
        background
      }}
    >
      {children}
    </div>
  )
}

export default Content
