import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { State } from 'stores'
import { active } from 'stores/effects/cursor'
import { setPageTo, setMenu } from 'stores/effects/page'

import { pages } from 'pages'

interface Props {
  title: string
}

const Sidebar: React.FC<Props> = ({ title }) => {
  const [start, setStart] = useState<boolean>(false)
  const [current, setCurrent] = useState<string>('')
  const [before, setBefore] = useState<string>('')
  const [animate, setAnimate] = useState<boolean>(false)

  const history = useHistory()

  const before__ = before.split('')
  const current__ = current.split('')

  const dispatch = useDispatch()
  const page = useSelector((state: State) => state.page)

  const enter = () => {
    dispatch(active(true))
  }

  const leave = () => {
    dispatch(active(false))
  }

  const contact = () => {
    dispatch(active(false))
    dispatch(setPageTo(5))
    history.push(pages[4])
    dispatch(setMenu(false))
  }

  useEffect(() => {
    if (!animate && current && current !== title) {
      setBefore(current)
      setCurrent(title)
      setAnimate(true)
      setStart(true)
      setTimeout(() => {
        setAnimate(false)
      }, 2000)
    } else {
      setCurrent(title)
    }
  }, [title, current, animate])
  return (
    <div className="loyal__app__sidebar">
      <div className="loyal__app__sidebar__navigation">
        <div className="loyal__app__sidebar__label">
          {animate && start ?
            <>
              <div className="loyal__app__sidebar__word hide">
                {before__.map((letter, index) =>
                  <div
                    key={index}
                    className="loyal__app__sidebar__letter"
                    style={{
                      animationDelay: `${index * (.2 / before__.length)}s`
                    }}
                  >
                    {letter || '&nbsp'}
                  </div>
                )}
              </div>
              <div className="loyal__app__sidebar__word show">
                {current__.map((letter, index) =>
                  <div
                    key={index}
                    className="loyal__app__sidebar__letter"
                    style={{
                      animationDelay: `${index * (.2 / current__.length)}s`
                    }}
                  >
                    {letter || '&nbsp'}
                  </div>
                )}
              </div>
            </>
            :
            <div className="loyal__app__sidebar__word">
              {current__.map((letter, index) =>
                <div
                  key={index}
                  className="loyal__app__sidebar__letter"
                >
                  {letter}
                </div>
              )}
            </div>
          }
        </div>
      </div>
      <div className="loyal__app__sidebar__navigation">
        <div
          className={classnames(
            'loyal__app__sidebar__menu',
            {
              'open': page.menu
            }
          )}
          onMouseEnter={enter}
          onMouseLeave={leave}
          onClick={() => dispatch(setMenu(!page.menu))}
        >
          <div className="loyal__app__sidebar__menu__animate">
            <div className="loyal__app__sidebar__menu__dot" />
            <div className="loyal__app__sidebar__menu__dot" />
          </div>
          <div className="loyal__app__sidebar__menu__dot" />
          <div className="loyal__app__sidebar__menu__animate">
            <div className="loyal__app__sidebar__menu__dot" />
            <div className="loyal__app__sidebar__menu__dot" />
          </div>
        </div>
      </div>
      <div className="loyal__app__sidebar__navigation">
        <div className="loyal__app__sidebar__label">
          <div className="loyal__app__sidebar__word contact">
            <div
              className="loyal__app__sidebar__letter"
              onMouseEnter={enter}
              onMouseLeave={leave}
              onClick={contact}
            >
              PLAN A PROJECT
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sidebar
