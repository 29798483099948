import React, { useState, useEffect, useRef, ChangeEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'

import LoyalService from 'services/loyal.project.service'
import { ProjectPlan } from 'types'
import { State } from 'stores'
import { setMove } from 'stores/effects/page'
import { active as setActive, text } from 'stores/effects/cursor'

import { Button, Text, Range } from 'components'
import { pages } from 'pages'

const Plan: React.FC = () => {
  const initialState: ProjectPlan = {
    name: '',
    email: '',
    whatsapp_number: '',
    company: '',
    type_project: [],
    description_project: '',
    budget_range: [10000, 100000],
    //timeline_project: [],
    source: '',
    created_at: new Date()
  }

  const [project, setProject] = useState<ProjectPlan>(initialState)
  const [submit, setSubmit] = useState<boolean>(false)
  const [send, setSend] = useState<boolean>(false)
  const [hide, setHide] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [emailValid, setEmailValid] = useState<boolean>(true)

  const name = useRef<HTMLInputElement>(null)
  const email = useRef<HTMLInputElement>(null)
  const whatsapp_number = useRef<HTMLInputElement>(null)
  const company = useRef<HTMLInputElement>(null)
  const description_project = useRef<HTMLTextAreaElement>(null)
  const source = useRef<HTMLInputElement>(null)

  const [step, setStep] = useState<number>(1)
  const step__count = 8
  const step__bar = 1 / step__count

  const page = useSelector((state: State) => state.page).page
  const active = page === pages.indexOf('/get-started') + 1

  const dispatch = useDispatch()

  const change = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setProject({ ...project, [name]: value })
    setError(false)
  }

  const keydown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (step === step__count) save()
      else next()
    }
  }

  const type = (value: string) => {
    const type = project.type_project || []
    const index = type.indexOf(value)
    if (index !== -1)
      type.splice(index, 1)
    else type.push(value)
    project.type_project = type
    setProject({ ...project })
  }

  const formatCurrency = (number: number) => {
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  }

  const range = (value: number[]) => {
    project.budget_range = value
    setProject({ ...project })
  }

  const enter = () => {
    dispatch(setActive(true))
  }

  const leave = () => {
    dispatch(setActive(false))
  }

  const back = () => {
    setHide(true)
    setTimeout(() => {
      setStep(step - 1)
      setHide(false)
    }, 500)
    setEmailValid(true)
    setError(false)
  }

  const next = () => {
    const fields = Object.keys(project)
    // eslint-disable-next-line 
    const value = eval(`project['${fields[step - 1]}']`)
    let valid = value || fields[step - 1] === 'type_project'
    if (fields[step - 1] === 'email' && !/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,}$/.test(value)) {
      valid = false
      if (value) setEmailValid(false)
      else setEmailValid(true)
    }
    if (valid) {
      if (step < step__count) {
        setHide(true)
        setTimeout(() => {
          setStep(step + 1)
          setHide(false)
        }, 500)
        setEmailValid(true)
      }
      setError(false)
    } else {
      setError(true)
    }
  }

  const save = () => {
    const fields = Object.keys(project)
    // eslint-disable-next-line 
    const value = eval(`project['${fields[step - 1]}']`)
    const valid = value
    if (valid) {
      setSubmit(true)
      LoyalService.create({ ...project })
        .then(() => {
          setSubmit(false)
          setSend(true)
          setHide(true)
          reset()
        })
        .catch(e => {
          setSubmit(false)
          setSend(false)
          setHide(false)
          console.log(e)
        })
      setError(false)
    } else {
      setError(true)
    }
  }

  const reset = () => {
    setProject(initialState)
    setSubmit(false)
  }

  useEffect(() => {
    // name.current?.focus()
    const keyDown = (e: any) => {
      if ((e.code === 'Enter' || e.code === 'NumpadEnter') && step !== 6) next()
    }
    window.addEventListener('keydown', keyDown)
    return () => {
      window.removeEventListener('keydown', keyDown)
    }
  })

  useEffect(() => {
    const fields = Object.keys(project)
    if (step < 5 || step === 6 || step === 8)
      // eslint-disable-next-line
      setTimeout(() => eval(`${fields[step - 1]}.current?.focus()`), 100)
  }, [step, project])

  const errors = [
    'Name field is required',
    'Email field is required',
    'Whatsapp Number field is required',
    'Company field is required',
    '',
    'Description field is required',
    '',
    'Source field is required'
  ]
  const error__email = 'Please use a valid email address'

  return (
    <div className="loyal__app__project__plan">
      {active &&
        <>
          <div
            className={classnames(
              'loyal__app__project__plan__step__bar', { 'hide': send }
            )}
            style={{
              transform: `scaleX(${step__bar * step})`
            }}
          />
          <div className="loyal__app__project__plan__form">
            <input
              ref={name}
              className={classnames({ 'show': step === 1, hide })}
              type="text"
              name="name"
              placeholder="Your Name"
              value={project.name}
              onChange={change}
              onKeyDown={keydown}
              onMouseOver={() => dispatch(text(true))}
              onMouseLeave={() => dispatch(text(false))}
              spellCheck="false"
              autoComplete="off"
              required
            />
            <input
              ref={email}
              className={classnames({ 'show': step === 2, hide })}
              type="email"
              name="email"
              placeholder="Email"
              value={project.email}
              onChange={change}
              onKeyDown={keydown}
              onMouseOver={() => dispatch(text(true))}
              onMouseLeave={() => dispatch(text(false))}
              spellCheck="false"
              autoComplete="off"
              required
            />
            <input
              ref={whatsapp_number}
              className={classnames({ 'show': step === 3, hide })}
              type="text"
              name="whatsapp_number"
              placeholder="Whatsapp"
              value={project.whatsapp_number}
              onChange={change}
              onKeyDown={keydown}
              onMouseOver={() => dispatch(text(true))}
              onMouseLeave={() => dispatch(text(false))}
              spellCheck="false"
              autoComplete="off"
              required
            />
            <input
              ref={company}
              className={classnames({ 'show': step === 4, hide })}
              type="text"
              name="company"
              placeholder="Your Company"
              value={project.company}
              onChange={change}
              onKeyDown={keydown}
              onMouseOver={() => dispatch(text(true))}
              onMouseLeave={() => dispatch(text(false))}
              spellCheck="false"
              autoComplete="off"
              required
            />
            <div
              className={classnames(
                'type',
                { 'show': step === 5, hide }
              )}
            >
              <span className="title">
                Choose All That Apply
              </span>
              <div
                className={classnames({ 'active': project.type_project?.indexOf('Loyalty Program') !== -1 })}
                onClick={() => type('Loyalty Program')}
              >
                <Text show cursor>Loyalty Program</Text>
              </div>
              <div
                className={classnames({ 'active': project.type_project?.indexOf('Marketing Website') !== -1 })}
                onClick={() => type('Marketing Website')}
              >
                <Text show cursor>Marketing Website</Text>
              </div>
              <div
                className={classnames({ 'active': project.type_project?.indexOf('Mobile Apps') !== -1 })}
                onClick={() => type('Mobile Apps')}
              >
                <Text show cursor>Mobile Apps</Text>
              </div>
            </div>
            <div className={
              classnames(
                'description',
                {
                  'show': step === 6, hide
                }
              )}
            >
              <textarea
                ref={description_project}
                name="description_project"
                placeholder="Tell us about your project..."
                spellCheck="false"
                onChange={e => setProject({ ...project, description_project: e.target.value })}
                onFocus={() => dispatch(setMove(true))}
                onBlur={() => dispatch(setMove(false))}
                onMouseOver={() => dispatch(text(true))}
                onMouseLeave={() => dispatch(text(false))}
              />
            </div>
            <div className={
              classnames(
                'budget',
                {
                  'show': step === 7, hide
                }
              )}
            >
              <span className="title">
                Budget Range
              </span>
              <div className="budget__range">
                <div>IDR {formatCurrency(project.budget_range?.[0] || 0)}K</div>
                <div>&nbsp;-&nbsp;</div>
                <div>IDR {formatCurrency(project.budget_range?.[1] || 0)}K</div>
              </div>
              <div
                onMouseEnter={enter}
                onMouseMove={enter}
                onMouseLeave={leave}
              >
                <Range
                  min={1000}
                  max={1000000}
                  value={project.budget_range}
                  step={1000}
                  renderThumb={(props: any, state: any) => <div {...props}>{state.valueNow}</div>}
                  minDistance={500}
                  onAfterChange={range}
                />
              </div>
            </div>
            <div
              className={classnames(
                'source', { 'show': step === 8, hide }
              )}
            >
              <span className="title">
                How’d You Hear About Us?
              </span>
            </div>
            <input
              ref={source}
              className={classnames({ 'show': step === 8, hide })}
              type="text"
              name="source"
              placeholder="Source"
              value={project.source}
              onChange={change}
              onKeyDown={keydown}
              onMouseOver={() => dispatch(text(true))}
              onMouseLeave={() => dispatch(text(false))}
              spellCheck="false"
              autoComplete="off"
              required
            />
            <div
              className={classnames(
                'success', { 'show': send, 'hide': !send }
              )}
            >
              <span className="title">
                You Really Did it.
              </span>
              <div>We'll be in touch.</div>
            </div>
          </div>
          {error &&
            <div className="loyal__app__project__plan__error">
              {!emailValid ? error__email : errors[step - 1]}
            </div>
          }
          {!send &&
            <>
              <div className="loyal__app__project__plan__step">
                {step} <span>/ {step__count}</span>
              </div>
              {!submit &&
                <div
                  className={classnames('button__wrapper', { 'single': step === 1 })}
                >
                  {step > 1 &&
                    <Button
                      onClick={back}
                      label={['BACK']}
                    />
                  }
                  {step < step__count &&
                    <Button
                      onClick={next}
                      label={['NEXT']}
                    />
                  }
                  {step === step__count &&
                    <Button
                      onClick={save}
                      label={['SUBMIT']}
                    />
                  }
                </div>
              }
              <div
                className={classnames(
                  'button__wrapper__single', { 'show': submit }
                )}
              >
                <Button
                  label={['SENDING', 'WAIT']}
                />
              </div>
            </>
          }
        </>
      }
    </div>
  )
}

export default Plan
