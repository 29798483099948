import React from 'react'
import ReactDOM from 'react-dom/client'
import {
  BrowserRouter as Router,
  Switch, Route
} from 'react-router-dom'

import { Provider } from 'react-redux'
import store from 'stores'

import App from 'components/app'
import PrivacyPolicy from 'pages/privacy-policy'
import Admin from 'components/admin'
import ResetPassword from 'pages/reset-password'

import reportWebVitals from 'reportWebVitals'
import 'index.scss'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/admin">
            <Admin />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route exact path="/customer/reset/:token">
            <ResetPassword />
          </Route>
          <Route exact path="*">
            <App />
          </Route>
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>
)

reportWebVitals()
