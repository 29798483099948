import {
  CursorAction,
  CURSOR_ACTIVE,
  CURSOR_TEXT
} from 'stores/interfaces/cursor'
import { Cursor } from 'types'

const initialState: Cursor = {
  active: false,
  text: false,
  radius: 0
}

export const cursor = (
  state = initialState,
  action: CursorAction
): Cursor => {
  switch (action.type) {
    case CURSOR_ACTIVE:
      return {
        ...state,
        active: action.active,
        radius: action.radius
      }
    case CURSOR_TEXT:
      return {
        ...state,
        text: action.text
      }
    default:
      return state
  }
}
