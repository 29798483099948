import firebase from 'firebase/app'
import { firestore, auth } from 'config'
import { ContactUs } from 'types'

const db = firestore.collection("/contact__us")

const getAll = () => {
  return db
}

const create = (data: ContactUs) => {
  return db.add(data)
}

const update = (id: string, value: ContactUs) => {
  return db.doc(id).update(value)
}

const remove = (id: string) => {
  return db.doc(id).delete()
}

const login = async (email: string, password: string) => {
  try {
    const user = await auth.signInWithEmailAndPassword(email, password)
    console.log(user)
  } catch (err: any) {
    console.log(err)
    alert(err.code)
  }
}

const logout = () => auth.signOut()

const authenticate = (callback: (data: firebase.User | null) => void) => {
  auth.onAuthStateChanged(user => callback(user))
}

const LoyalService = {
  getAll,
  create,
  update,
  remove,
  login,
  logout,
  authenticate
}

export default LoyalService
