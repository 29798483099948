import React, { useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'

import { Image } from 'components'

import './styles.scss'
import axios from 'axios'

interface ParamTypes {
  token: string
}

const ResetPassword: React.FC = () => {
  const params = useParams<ParamTypes>()
  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const type = searchParams.get('type')
  const dev = searchParams.get('dev')
  const client_code = searchParams.get('client_code')

  const [password, setPassword] = useState<string>('')
  const [passwordConfirm, setPasswordConfirm] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [success, setSuccess] = useState<string>('')
  const [error, setError] = useState<string>('')

  const submit = async () => {
    try {
      setLoading(true)
      if (type === 'retail') {
        const validate = /^(?=[^A-Za-z]*[A-Za-z])(?=.*\d).{8,}$/.test(password)
        if (password !== passwordConfirm) {
          setError('Passwords do not match')
          setLoading(false)
          return false
        }
        if (!validate) {
          setError('The password must be at least 8 characters, a combination of letters and numbers')
          setLoading(false)
          return false
        }
      }
      const formData = new FormData()
      formData.append('client_code', client_code || '0410101')
      formData.append('token', params.token)
      formData.append('password', password)
      formData.append('password_confirmation', passwordConfirm)
      const baseUrl = type === 'retail' ? dev === 'true' ? 'https://retail-dev.loyal.id' : 'https://admin.loyal.id' : 'https://portal-api-srv.loyal.id'
      const res = await axios.post(`${baseUrl}/api/v1/validate/forgot-password`,
        formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*'
        },
        transformRequest: formData => formData
      })
      const { status_code, message } = res.data
      if (status_code === 200) {
        setSuccess(message || 'Successfully reset your password.')
        setLoading(false)
      } else {
        setError(message || 'Network error')
        setLoading(false)
      }
    } catch (e: any) {
      setError(e?.response?.data?.message || 'Network error')
      setLoading(false)
    }
  }
  return (
    <div className="loyal__app__reset__password">
      <Image src="loyalid.svg" path="logo" height="50" />
      {success ?
        <div className="success">{success}</div>
        :
        <div className="form">
          <div>
            <input
              type="password"
              placeholder="New Password"
              onChange={e => {
                setPassword(e.target.value)
                setError('')
              }}
            />
          </div>
          <div>
            <input
              type="password"
              placeholder="Password Confirmation"
              onChange={e => {
                setPasswordConfirm(e.target.value)
                setError('')
              }} />
          </div>
          <div>
            <button onClick={submit}>
              {!loading ?
                <span>Reset Password</span>
                :
                <Image src="loading.svg" path="images" height="20" />
              }
            </button>
          </div>
          {error && <div className="error">{error}</div>}
        </div>
      }
    </div>
  )
}

export default ResetPassword
