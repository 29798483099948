import {
  CursorAction,
  CURSOR_ACTIVE,
  CURSOR_TEXT
} from 'stores/interfaces/cursor'

export const setActive = (active: boolean, radius: number): CursorAction => ({
  type: CURSOR_ACTIVE,
  active,
  radius
})

export const setText = (text: boolean): CursorAction => ({
  type: CURSOR_TEXT,
  text
})
