import { Component } from 'react'

const formatCurrency = number => {
  return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

class Range extends Component {
  constructor(props) {
    super(props)
    let value = this.sanitizeInValue(props.value)
    if (!value.length)
      value = this.sanitizeInValue(props.defaultValue)

    this.tempArray = value.slice()
    this.pendingResizeTimeouts = []

    const zIndices = []
    for (let i = 0; i < value.length; i++) {
      value[i] = this.trimAlignValue(value[i], props)
      zIndices.push(i)
    }

    this.state = {
      index: -1,
      upperBound: 0,
      sliderLength: 0,
      value,
      zIndices
    }
  }

  componentDidMount = () => {
    window.addEventListener('resize', this.handleResize)
    this.resize()
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      return {
        value: props.value
      }
    }
    return null
  }

  // UNSAFE_componentWillReceiveProps(newProps) {
  //   const state = this.state
  //   let value = this.sanitizeInValue(newProps.value)
  //   if (!value.length) value = state.value
  //   this.tempArray = value.slice()

  //   for (let i = 0; i < value.length; i++) {
  //     // eslint-disable-next-line react/no-direct-mutation-state
  //     state.value[i] = this.trimAlignValue(value[i], newProps)
  //   }
  //   if (state.value.length > value.length)
  //     // eslint-disable-next-line react/no-direct-mutation-state
  //     state.value.length = value.length
  // }

  componentDidUpdate = () => {
    const { upperBound } = this.state
    if (upperBound === 0) this.resize()
  }

  componentWillUnmount = () => {
    this.clearPendingResizeTimeouts()
    window.removeEventListener('resize', this.handleResize)
  }

  onKeyUp = () => {
    this.onEnd()
  };

  onMouseUp = () => {
    this.onEnd(this.getMouseEventMap())
  };

  onTouchEnd = () => {
    this.onEnd(this.getTouchEventMap())
  };

  onBlur = () => {
    this.setState({ index: -1 }, this.onEnd(this.getKeyDownEventMap()))
  };

  onEnd = (eventMap) => {
    if (eventMap) this.removeHandlers(eventMap)
    if (this.hasMoved) this.fireChangeEvent('onAfterChange')
    this.hasMoved = false
  }

  onMouseMove = e => {
    const position = this.getMousePosition(e)
    const diffPosition = this.getDiffPosition(position[0])
    const newValue = this.getValueFromPosition(diffPosition)
    this.move(newValue)
  }

  onTouchMove = e => {
    if (e.touches.length > 1) return

    const position = this.getTouchPosition(e)

    if (typeof this.isScrolling === 'undefined') {
      const diffMainDir = position[0] - this.startPosition[0]
      const diffScrollDir = position[1] - this.startPosition[1]
      this.isScrolling = Math.abs(diffScrollDir) > Math.abs(diffMainDir)
    }

    if (this.isScrolling) {
      this.setState({ index: -1 })
      return
    }

    const diffPosition = this.getDiffPosition(position[0])
    const newValue = this.getValueFromPosition(diffPosition)

    this.move(newValue)
  }

  onKeyDown = e => {
    const { min, max, step, pageFn } = this.props
    if (e.ctrlKey || e.shiftKey || e.altKey) return
    switch (e.key) {
      case 'ArrowLeft':
      case 'ArrowDown':
      case 'Left':
      case 'Down':
        e.preventDefault()
        this.moveDownByStep()
        break
      case 'ArrowRight':
      case 'ArrowUp':
      case 'Right':
      case 'Up':
        e.preventDefault()
        this.moveUpByStep()
        break
      case 'Home':
        e.preventDefault()
        this.move(Number(min))
        break
      case 'End':
        e.preventDefault()
        this.move(Number(max))
        break
      case 'PageDown':
        e.preventDefault()
        this.moveDownByStep(pageFn(Number(step)))
        break
      case 'PageUp':
        e.preventDefault()
        this.moveUpByStep(pageFn(Number(step)))
        break
      default:
    }
  }

  onSliderMouseDown = e => {
    const { disabled, snapDragDisabled } = this.props
    if (disabled || e.button === 2) return
    if (!snapDragDisabled) {
      const position = this.getMousePosition(e)
      this.forceValueFromPosition(position[0], i => {
        this.start(i, position[0])
        this.addHandlers(this.getMouseEventMap())
      })
    }
    this.pauseEvent(e)
  }

  pauseEvent = e => {
    e.stopPropagation()
    e.preventDefault()
    return false
  }

  sanitizeInValue = x => {
    if (!x) return []
    return Array.isArray(x) ? x.slice() : [x]
  }

  prepareOutValue = x => {
    return x && x.length === 1 ? x[0] : x.slice()
  }

  trimSucceeding = (length, nextValue, minDistance, max) => {
    for (let i = 0; i < length; i++) {
      const padding = Number(max) - i * Number(minDistance)
      if (nextValue[length - 1 - i] > padding) nextValue[length - 1 - i] = padding
    }
  }

  trimPreceding = (length, nextValue, minDistance, min) => {
    for (let i = 0; i < length; i++) {
      const padding = Number(min) + i * Number(minDistance)
      if (nextValue[i] < padding) nextValue[i] = padding
    }
  }

  addHandlers = eventMap => {
    Object.keys(eventMap).forEach(key => {
      if (typeof document !== 'undefined')
        document.addEventListener(key, eventMap[key], false)
    })
  }

  removeHandlers = eventMap => {
    Object.keys(eventMap).forEach(key => {
      if (typeof document !== 'undefined')
        document.removeEventListener(key, eventMap[key], false)
    })
  }

  onSliderClick = e => {
    const { disabled, onSliderClick } = this.props
    if (disabled) return

    if (onSliderClick && !this.hasMoved) {
      const position = this.getMousePosition(e);
      const valueAtPos = this.trimAlignValue(
        this.calcValue(this.calcOffsetFromPosition(position[0]))
      )
      onSliderClick(valueAtPos)
    }
  }

  getValue = () => {
    const { value } = this.state
    return this.prepareOutValue(value)
  }

  getClosestIndex = pixelOffset => {
    const { value } = this.state
    let minDist = Number.MAX_VALUE
    let closestIndex = -1

    for (let i = 0; i < value.length; i++) {
      const offset = this.calcOffset(value[i])
      const dist = Math.abs(pixelOffset - offset)
      if (dist < minDist) {
        minDist = dist
        closestIndex = i
      }
    }
    return closestIndex
  }

  getMousePosition = e => {
    return [e[`page${this.axisKey()}`], e[`page${this.orthogonalAxisKey()}`]]
  }

  getTouchPosition = e => {
    const touch = e.touches[0]
    return [touch[`page${this.axisKey()}`], touch[`page${this.orthogonalAxisKey()}`]]
  }

  getKeyDownEventMap = () => {
    return {
      keydown: this.onKeyDown,
      keyup: this.onKeyUp,
      focusout: this.onBlur
    }
  }

  getMouseEventMap = () => {
    return {
      mousemove: this.onMouseMove,
      mouseup: this.onMouseUp
    }
  }

  getTouchEventMap = () => {
    return {
      touchmove: this.onTouchMove,
      touchend: this.onTouchEnd
    }
  }

  getValueFromPosition = position => {
    const { min, max } = this.props
    const { sliderLength, thumbSize, startValue } = this.state
    const diffValue =
      (position / (sliderLength - thumbSize)) *
      (Number(max) - Number(min))
    return this.trimAlignValue(startValue + diffValue)
  }

  getDiffPosition = position => {
    const { invert } = this.props
    const { startPosition } = this.state
    let diffPosition = position - startPosition
    if (invert) diffPosition *= -1
    return diffPosition
  }

  createOnKeyDown = i => e => {
    const { disabled } = this.props
    if (disabled) return
    this.start(i)
    this.addHandlers(this.getKeyDownEventMap())
    this.pauseEvent(e)
  }

  createOnMouseDown = i => e => {
    const { disabled } = this.props
    if (disabled || e.button === 2) return
    const position = this.getMousePosition(e)
    this.start(i, position[0])
    this.addHandlers(this.getMouseEventMap())
    this.pauseEvent(e)
  }

  createOnTouchStart = i => e => {
    e.stopPropagation()
    const { disabled } = this.props
    if (disabled || e.touches.length > 1) return
    const position = this.getTouchPosition(e)
    this.startPosition = position
    this.isScrolling = undefined
    this.start(i, position[0])
    this.addHandlers(this.getTouchEventMap())
  }

  handleResize = () => {
    const resizeTimeout = setTimeout(() => {
      this.pendingResizeTimeouts.shift()
      this.resize()
    }, 0)
    this.pendingResizeTimeouts.push(resizeTimeout)
  }

  resize = () => {
    const { slider, thumb0: thumb } = this
    const state = this.state
    if (!slider || !thumb) return

    const sizeKey = this.sizeKey()
    const sliderRect = slider.getBoundingClientRect()
    const sliderSize = slider[sizeKey]
    const sliderMax = sliderRect[this.posMaxKey()]
    const sliderMin = sliderRect[this.posMinKey()]
    const thumbRect = thumb.getBoundingClientRect()
    const thumbSize = thumbRect[sizeKey.replace('client', '').toLowerCase()]
    const upperBound = sliderSize - thumbSize
    const sliderLength = Math.abs(sliderMax - sliderMin)

    if (
      state.upperBound !== upperBound ||
      state.sliderLength !== sliderLength ||
      state.thumbSize !== thumbSize
    ) {
      this.setState({
        upperBound,
        sliderLength,
        thumbSize
      })
    }
  }

  calcOffset = value => {
    const { min, max } = this.props
    const { upperBound } = this.state
    const range = Number(max) - Number(min)
    if (range === 0) return 0
    const ratio = (value - Number(min)) / range
    return ratio * upperBound
  }

  calcValue = offset => {
    const { min, max } = this.props
    const { upperBound } = this.state
    const ratio = offset / upperBound
    return ratio * (Number(max) - Number(min)) + Number(min)
  }

  calcOffsetFromPosition = position => {
    const { invert } = this.props
    const { thumbSize, sliderLength } = this.state
    const sliderRect = this.slider.getBoundingClientRect()
    const sliderMax = sliderRect[this.posMaxKey()]
    const sliderMin = sliderRect[this.posMinKey()]

    const windowOffset = window[`page${this.axisKey()}Offset`]
    const sliderStart = windowOffset + (invert ? sliderMax : sliderMin)

    let pixelOffset = position - sliderStart
    if (invert) pixelOffset = sliderLength - pixelOffset
    pixelOffset -= thumbSize / 2
    return pixelOffset
  }

  forceValueFromPosition = (position, callback) => {
    const { minDistance } = this.props
    let { value } = this.state
    const pixelOffset = this.calcOffsetFromPosition(position)
    const closestIndex = this.getClosestIndex(pixelOffset)
    const nextValue = this.trimAlignValue(this.calcValue(pixelOffset))

    value = value.slice()
    value[closestIndex] = nextValue

    for (let i = 0; i < value.length - 1; i++) {
      if (value[i + 1] - value[i] < Number(minDistance)) return
    }

    this.fireChangeEvent('onBeforeChange')
    this.hasMoved = true
    this.setState({ value }, () => {
      callback(closestIndex)
      this.fireChangeEvent('onChange')
    })
  }

  clearPendingResizeTimeouts = () => {
    do {
      const nextTimeout = this.pendingResizeTimeouts.shift()
      clearTimeout(nextTimeout)
    } while (this.pendingResizeTimeouts.length)
  }

  start = (i, position) => {
    const { zIndices } = this.state
    const thumbRef = this[`thumb${i}`]
    if (thumbRef) thumbRef.focus()

    zIndices.splice(zIndices.indexOf(i), 1)
    zIndices.push(i)

    this.setState(prevState => ({
      startValue: prevState.value[i],
      startPosition: position !== undefined ? position : prevState.startPosition,
      index: i,
      zIndices
    }))
  }

  moveUpByStep = (step = this.props.step) => {
    const { max } = this.props
    const { value, index } = this.state
    const oldValue = value[index]
    const newValue = this.trimAlignValue(oldValue + Number(step))
    this.move(Math.min(newValue, Number(max)))
  }

  moveDownByStep = (step = this.props.step) => {
    const { min } = this.props
    const { value, index } = this.state
    const oldValue = value[index]
    const newValue = this.trimAlignValue(oldValue - Number(step))
    this.move(Math.max(newValue, Number(min)))
  }

  move = (newValue) => {
    const { pearling, max, min, minDistance } = this.props
    const { index, value } = this.state

    const oldValue = value[index]
    if (newValue === oldValue) return
    if (!this.hasMoved) this.fireChangeEvent('onBeforeChange')

    this.hasMoved = true

    if (!pearling) {
      if (index > 0) {
        const valueBefore = value[index - 1];
        if (newValue < valueBefore + Number(minDistance))
          newValue = valueBefore + Number(minDistance)
      }

      if (index < value.length - 1) {
        const valueAfter = value[index + 1];
        if (newValue > valueAfter - Number(minDistance))
          newValue = valueAfter - Number(minDistance)
      }
    }

    value[index] = newValue

    if (pearling && value.length > 1) {
      if (newValue > oldValue) {
        this.pushSucceeding(value, Number(minDistance), index)
        this.trimSucceeding(value.length, value, Number(minDistance), Number(max))
      } else if (newValue < oldValue) {
        this.pushPreceding(value, Number(minDistance), index)
        this.trimPreceding(value.length, value, Number(minDistance), Number(min))
      }
    }

    this.setState({ value }, this.fireChangeEvent(this, 'onChange'))
  }

  pushSucceeding = (value, minDistance, index) => {
    let i
    let padding
    for (
      i = index, padding = value[i] + Number(minDistance);
      value[i + 1] !== null && padding > value[i + 1];
      i++, padding = value[i] + Number(minDistance)
    ) {
      value[i + 1] = this.alignValue(padding)
    }
  }

  pushPreceding = (value, minDistance, index) => {
    for (
      let i = index, padding = value[i] - Number(minDistance);
      value[i - 1] !== null && padding < value[i - 1];
      i--, padding = value[i] - Number(minDistance)
    ) {
      value[i - 1] = this.alignValue(padding)
    }
  }

  axisKey = () => {
    const { orientation } = this.props
    if (orientation === 'vertical') return 'Y'
    return 'X'
  }

  orthogonalAxisKey = () => {
    const { orientation } = this.props
    if (orientation === 'vertical') return 'X'
    return 'Y'
  }

  posMinKey = () => {
    const { orientation, invert } = this.props
    if (orientation === 'vertical')
      return invert ? 'bottom' : 'top'
    return invert ? 'right' : 'left'
  }

  posMaxKey = () => {
    const { orientation, invert } = this.props
    if (orientation === 'vertical')
      return invert ? 'top' : 'bottom'
    return invert ? 'left' : 'right'
  }

  sizeKey = () => {
    const { orientation } = this.props
    if (orientation === 'vertical') return 'clientHeight'
    return 'clientWidth'
  }

  trimAlignValue = (val, props) => {
    return this.alignValue(this.trimValue(val, props), props)
  }

  trimValue = (val, props = this.props) => {
    let trimmed = val
    if (trimmed <= props.min) trimmed = Number(props.min)
    if (trimmed >= props.max) trimmed = Number(props.max)
    return trimmed
  }

  alignValue = (val, props = this.props) => {
    const valModStep = (val - props.min) % Number(props.step)
    let alignValue = val - valModStep

    if (Math.abs(valModStep) * 2 >= Number(props.step))
      alignValue += valModStep > 0 ? Number(props.step) : -Number(props.step)

    return parseFloat(alignValue.toFixed(5))
  }

  fireChangeEvent = event => {
    const { value } = this.state
    if (this.props[event])
      this.props[event](this.prepareOutValue(value))
  }

  buildThumbStyle = (offset, i) => {
    const { index, zIndices } = this.state
    const style = {
      position: 'absolute',
      touchAction: 'none',
      willChange: index >= 0 ? this.posMinKey() : '',
      zIndex: zIndices.indexOf(i) + 1
    };
    style[this.posMinKey()] = `${offset}px`
    return style
  }

  buildTrackStyle = (min, max) => {
    const { index } = this.state
    const obj = {
      position: 'absolute',
      willChange: index >= 0 ? `${this.posMinKey()},${this.posMaxKey()}` : ''
    }
    obj[this.posMinKey()] = Number(min)
    obj[this.posMaxKey()] = Number(max)
    return obj
  }

  renderThumb = (style, i) => {
    const { renderThumb } = this.props
    const { value, index } = this.state
    const className = `loyal__app__range__thumb loyal__app__range__thumb__${i}
      ${index === i ? 'loyal__app__range__active' : ''}`

    const props = {
      'ref': r => {
        this[`thumb${i}`] = r
      },
      'key': `loyal__app__range__thumb__${i}`,
      className,
      style,
      'tabIndex': 0,
      'role': 'slider',
      'onMouseDown': this.createOnMouseDown(i),
      'onTouchStart': this.createOnTouchStart(i),
      'onFocus': this.createOnKeyDown(i),
      'data-value': `IDR ${formatCurrency(value[i])}K`
    }

    const state = {
      index: i,
      value: this.prepareOutValue(value),
      valueNow: value[i]
    }

    return renderThumb(props, state)
  }

  renderThumbs = offset => {
    const styles = this.tempArray;
    for (let i = 0; i < offset.length; i++) {
      styles[i] = this.buildThumbStyle(offset[i], i)
    }

    const res = []
    for (let i = 0; i < offset.length; i++) {
      res[i] = this.renderThumb(styles[i], i)
    }
    return res
  }

  renderTrack = (i, offsetFrom, offsetTo) => {
    const { renderTrack } = this.props
    const { upperBound, value } = this.state
    const props = {
      key: `loyal__app__range__track__${i}`,
      className: `loyal__app__range__track loyal__app__range__track__${i}`,
      style: this.buildTrackStyle(offsetFrom, upperBound - offsetTo)
    }
    const state = {
      index: i,
      value: this.prepareOutValue(value)
    }
    return renderTrack(props, state)
  };

  renderTracks = offset => {
    const { upperBound } = this.state
    const tracks = []
    const lastIndex = offset.length - 1

    tracks.push(this.renderTrack(0, 0, offset[0]))
    for (let i = 0; i < lastIndex; i++) {
      tracks.push(this.renderTrack(i + 1, offset[i], offset[i + 1]))
    }
    tracks.push(this.renderTrack(lastIndex + 1, offset[lastIndex], upperBound))
    return tracks
  }

  render() {
    const offset = this.tempArray
    const { withTracks } = this.props
    const { value } = this.state
    for (let i = 0; i < value.length; i++) {
      offset[i] = this.calcOffset(value[i], i)
    }

    const tracks = withTracks ? this.renderTracks(offset) : null
    const thumbs = this.renderThumbs(offset)

    return (
      <div
        ref={r => this.slider = r}
        className="loyal__app__range"
        onMouseDown={this.onSliderMouseDown}
        onClick={this.onSliderClick}
      >
        {tracks}
        {thumbs}
      </div>
    )
  }
}

Range.defaultProps = {
  min: 0,
  max: 100,
  step: 1,
  pageFn: step => step * 10,
  minDistance: 0,
  defaultValue: 0,
  orientation: 'horizontal',
  withTracks: true,
  pearling: false,
  disabled: false,
  snapDragDisabled: false,
  invert: false,
  renderThumb: props => <div {...props} />,
  renderTrack: props => <div {...props} />
}

export default Range
