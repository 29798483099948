import { combineReducers } from 'redux'
import { cursor } from 'stores/reducers/cursor'
import { page } from 'stores/reducers/page'

const rootReducer = combineReducers({
  cursor,
  page
})

export default rootReducer
