import React from 'react'
import { useSelector } from 'react-redux'

import { State } from 'stores'
import { Content, Text } from 'components'

const About: React.FC = () => {
  const state = useSelector((state: State) => state.page)
  const page = state.page
  const active = page === 2
  const long__text = 'You get to know your loyal customers by knowing their interests, insights and behaviour based on data. Our analytics system collects data from your registered customers to help you maintain your loyal customers by identifying the prospective model and giving their precise needs.'
  return (
    <Content active={active}>
      <div className="loyal__app__about">
        <div className="loyal__app__about__right__panel">
          <div className="loyal__app__about__right__panel__top">
            <div className="loyal__app__about__right__panel__header">
              <Text show={active}>
                REDIFINING&nbsp;
              </Text>
              <Text delay=".2" show={active}>
                LOYALTY&nbsp;
              </Text>
              <Text delay=".4" show={active}>
                PROGRAM<span>.</span>
              </Text>
            </div>
            <div className="loyal__app__about__right__panel__text">
              <Text show={active}>
                We make loyalty program services that suits every business needs.
              </Text>
            </div>
            <div className="loyal__app__about__right__panel__column">
              <div>
                <div className="loyal__app__about__right__panel__column__title">
                  <Text show={active}>
                    CUSTOMER
                  </Text>
                  <Text delay=".2" show={active}>
                    ENGAGEMENT
                  </Text>
                </div>
                <div className="loyal__app__about__right__panel__column__text">
                  <Text delay=".4" show={active}>Business Intelligence</Text>
                  <Text delay=".6" show={active}><div className="bullets" />Online Reporting</Text>
                  <Text delay=".8" show={active}><div className="bullets" />Data Analytics</Text>
                  <Text delay="1" show={active}>  <div className="bullets" />RFM Segmentation</Text>
                  <Text delay="1.2" show={active}><div className="bullets" />Predictive Modeling</Text>
                </div>
              </div>
              <div>
                <div className="loyal__app__about__right__panel__column__title">
                  <Text show={active}>
                    WITH
                  </Text>
                  <Text delay=".2" show={active}>
                    LOYAL.ID
                  </Text>
                </div>
                <div className="loyal__app__about__right__panel__column__long__text">
                  {long__text.split(' ').map((item, index) =>
                    <Text delay={.4 + (index * .05)} show={active} key={index}>
                      {item} &nbsp;
                    </Text>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Content >
  )
}

export default About
