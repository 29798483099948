import React from 'react'
import classnames from 'classnames'
import { useSpring, a } from '@react-spring/web'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { State } from 'stores'
import { Image } from 'components'

const Logo: React.FC = () => {
  const menu = useSelector((state: State) => state.page).menu

  const location = useLocation()

  const light = ['/', '/products', '/get-started', '/contact-us'].includes(location.pathname) && !menu
  let logo = light ? 'white' : 'blue'

  const calc = (x: number, y: number) => [-(y - window.innerHeight / 2) / 80, (x - window.innerWidth / 2) / 80, 1.2]
  const trans = (x: number, y: number, s: number) => `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`
  const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 5, tension: 1000, friction: 50 } }))

  return (
    <a.div
      className={classnames('loyal__app__logo', { [logo]: true })}
      onMouseMove={({ clientX: x, clientY: y }) => set.start({ xys: calc(x, y) })}
      onMouseLeave={() => set.start({ xys: [0, 0, 1] })}
      style={{ transform: props.xys.to(trans) }}
    >
      <div className="loyal__app__logo__inner">
        <div className="loyal__app__logo__white">
          <Image src="loyalid.svg" path="logo" height="35" />
        </div>
        <div className="loyal__app__logo__blue">
          <Image src="loyalid-blue.svg" path="logo" height="35" />
        </div>
      </div>
    </a.div>
  )
}

export default Logo
