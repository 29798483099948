import React from 'react'

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy-policy">
      <div className="content">
        <h1>Privacy Policy</h1>
        <p>
          The following Privacy Policy describes how we collect, store, use, transfer, disclose and protect Your personal information which can be identified and obtained through LOYAL.ID application and/or our website. Please read this Privacy Policy thoroughly to ensure that You understand how We apply this Privacy Policy.
          Your use of LOYAL.ID Application and services is subject to the Terms of Use and this Privacy Policy and indicates Your consent over the terms of Use and Privacy Policy.
        </p>

        <h3>1. DEFINITION</h3>
        <p>In this Privacy Policy, unless expressly provided otherwise, the following expressions have the following meanings:</p>
        <p>“You” means the party registered as a LOYAL.ID Application user to use the Services provided in the LOYAL.ID Application.</p>
        <p>“LOYAL.ID Application” is a mobile app with the name LOYAL.ID for Memberships, which can be downloaded via playstore for Android users as well as through via app store for iOS users that We develop to support Our Services provided.</p>
        <p>“Personal Information” means information regarding Yourself which can be privately identified and collected through the LOYAL.ID Aplikasi, such as name, address, date of birth and occupation (if You are a person), corporate data and identity document (if You are not a person), phone number, email address and other information which may identify You as an user of LOYAL.ID Application.</p>
        <p>“We/Our/Us (Kami)” means PT Loyal Member Indonesia, a company established under the laws of the Republic of Indonesia.</p>
        <p>“Terms of Use” means terms and condition or standard operational procedures or other terms in connection with LOYAL.ID Application or website developed by Us.</p>
        <p>“Service” means various services offered by LOYAL.ID Application or website.</p>
        <p>“Customer Service” is the customer service center We provide for You and can be reached by phone calls 02122474990 and/or email info@loyal.id and/or live chat in LOYAL.ID Application and can be used when You have problems in the use of LOYAL.ID Application or when you use Our Service.</p>
        <p>“Cookie” is a small data file placed in Your browser on Your internet device. With cookie, the feature of LOYAL.ID Application and/or website that You access can save information or remember Your actions and preferences from time to time.</p>
        <p>“Website” means Our website at www.loyal.id or other sites which We developed officially to support the Service ecosystem.</p>
        <h3>2. INFORMATION WHICH WE COLLECT</h3>
        <p>We collect Personal Information from You so that the LOYAL.ID Application can perform its function in connection with the Service We provide. You can provide Personal Information directly or collected automatically when You use the LOYAL.ID Application.</p>
        <p>The information will be uploaded through our system with subdomain portal.loyal.id. Another subdomain under loyal.id domain might be used in some incidents. All subdomains under loyal.id is reviewed every week by LOYAL.id’s security team.</p>

        <p>Information that You provide directly</p>
        <p>When registering to the LOYAL.ID Application, You will provide to Us certain Personal Information  pursuant to Terms of Use required by each type and function of the Application.</p>
        <p>Information which We collect when You use the Application</p>
        <p>To utilize the Services We provide to You, You provide Us with information as relevant, including but not limited to information such as Your name, shipping address and email address. When You use LOYAL.ID Application, We will also process Your technical data such as, internet protocol address, internet device identity (ID) or media access control address, and information regarding the manufacturer, model and operating system of the device that You use to access the LOYAL.ID Application. We use these data to allow Us to send the LOYAL.ID Application’s functions, to resolve technical difficulties, to provide You the correct and updated version of the LOYAL.ID Application as well as to improve the LOYAL.ID Application’s function.</p>
        <p>Use of Cookie</p>
        <p>We use cookie for several purposes. We use it, for example, to remember Your safe search preferences, to help You to use the Service, and to protect Your data.</p>

        <h3>3. THE USE OF INFORMATION WHICH WE COLLECT</h3>
        <p>We use Your email address, name, phone number and/or account password to verify Your ownership over an account in LOYAL.ID Application, to communicate with You in connection with the use of the Service and to give You information regarding the LOYAL.ID Application. We may also use Your name, email address and phone number to send message and/or make phone calls to provide special offers or promotions that are being held (both by Us and other parties who have collaborated with Us), providing  general updates on the LOYAL.ID Application; and).</p>
        <p>We use Your Personal Information entirely to analyze the LOYAL.ID Application’s pattern of use. You hereby agree that Your data will be used by Our internal data processing system to ensure the delivery of the best function of the Application to You.</p>
        <p>Related to special offers or promotions that are being held (both by Us and/or other parties who have collaborated with Us), then You can ask Us to do not (i) sending messages to your e-mail address which is registered by pressing the unsubscribe button on every email We send, and / or (ii) make phone calls by placing your phone number in the "Do Not Call" list that We have by sending your request to Our email address: info@loyal.id.</p>

        <h3>4. SHARING OF INFORMATION WHICH WE COLLECT</h3>
        <p>We may employ or cooperate with companies and persons as third parties to facilitate or provide support in developing the LOYAL.ID Application and certain services to Us and/or on Our behalf , to, among others:</p>
        <p>(i) provide customer’s assistance;</p>
        <p>(ii) perform Services relating to website (including but not limited to maintenance, database processing, web analysis and website feature perfecting services);</p>
        <p>(iii) assist Us in analyzing how the LOYAL.ID Application and Services are used as well as their development;</p>
        <p>(iv) to assist Our professional advisor, including legal advisor, financial advisor, and consultants. These third parties have access over Your Personal Information only to perform those tasks for Us and/or on Our behalf and [the third parties] are contractually bound not to disclose or use the Personal Information for any other purpose.</p>
        <p>We will disclose Your Personal Information to the extent it is required by law or required to comply with the provisions of the laws and regulations, government institution, or in case of a dispute, or any form of legal proceeding between You and Us, or between You and other user in connection with, or relating to the Service.</p>
        <p>Your Personal Information may be transferred, stored, used, and processed in a jurisdiction other than Indonesia where Our servers are located.</p>
        <p>We may eliminate certain parts of Your Personal Information which may identify You (so the Personal Information becomes anonymous) and disclose the anonymous data to a third party for the purposes of development the LOYAL.ID Application, database managemet, Service’s analysis or improvement. We also may combine Your Personal Information with other information in a way that the information is no longer associated with You, and later disclose the combined information to the third party, for the above mentioned purposes.</p>
        <p>We shall not sell or lease Your Personal Information to a third party.</p>

        <h3>5. SECURITY</h3>
        <p>Confidentiality of your data and Personal Information is the most important matter for Us. We will conduct the best effort and steps to protect and secure Your data and Personal Information. However, We cannot fully guarantee that Our system is totally impenetrable by virus, malware, disruption or extraordinary occurrence including unauthorized access by a third party. You may not disclose Your account password to anyone and must always maintain the security of the device You use.</p>

        <h3>6. CHANGES TO THIS PRIVACY POLICY</h3>
        <p>We may change in this Privacy Policy to reflect the changes in Our activity. We advise You to visit this page periodically to obtain the latest information regarding how We enforce this Privacy Policy.</p>

        <h3>7. ACKNOWLEDGEMENT AND CONSENT</h3>
        <p>By using LOYAL.ID Application and/or Our website, You acknowledge that You have read and understood this Privacy Policy and the Terms of Use and agree and accede to the use, practice, processing and transfer of Your Personal Information by Us as stated in this Privacy Policy. You also represent that You are entitled to share all of the information You have provided to Us and to give Us the power to use  and share such information to support and perform the function of LOYAL.ID Application.</p>

        <h3>8. CONSENT REVOCATION AND DELETION</h3>
        <p>Consent revocation or deletion of Your Personal Information whether all or in part, may no longer allow You to use LOYAL.ID Application (including the Services We provided) and/or Our website. You hereby state that You have acknowledged and understood the consequences of the consent revocation and/or the deletion of Your Personal Information.</p>

        <h3>9. MISCELLANEOUS</h3>
        <p>This Privacy Policy shall be regulated and interpreted pursuant to the laws of any jurisdiction where the LOYAL.ID Application is used. All disputes arising from the use of Our Service will be subject to the exclusive jurisdiction of the North Jakarta District Court.</p>
        <p>This Privacy Policy is made in the Indonesian and English languages. Specifically for Indonesia, in the event of any inconsistency between the Indonesian language version and the English version, the Indonesian language version will prevail.</p>
        <p>If You have further questions regarding Your Privacy and information security or Your wish to update or delete Your data, please contact Our Customer Service at: info@loyal.id or call: 02122474990</p>
      </div>
    </div>
  )
}

export default PrivacyPolicy
