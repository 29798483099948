import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { useSelector } from 'react-redux'

import { State } from 'stores'
import { Image } from 'components'

const IntroImage: React.FC = () => {
  const imageSize = window.innerWidth * (24 / 100)
  const [size, setSize] = useState<number>(imageSize)

  const state = useSelector((state: State) => state.page)
  const to = Number(state.pageTo)

  const next__2 = to === 2
  const next__3 = to === 3
  const next__to = to > 3

  const resize = () => {
    const imageSize = window.innerWidth * (24 / 100)
    setSize(imageSize)
  }

  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  })

  return (
    <div
      className={classnames(
        'loyal__app__intro__image', { next__2, next__3, next__to }
      )}
    >
      <div className="loyal__app__intro__image__inner">
        <Image src="image-1.png" width={size} parallax />
      </div>
    </div>
  )
}

export default IntroImage
