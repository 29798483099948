import React, { useState, useEffect, useRef, ChangeEvent } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'

import LoyalService from 'services/loyal.service'
import { ContactUs } from 'types'
import { State } from 'stores'
import { setMove } from 'stores/effects/page'
import { text } from 'stores/effects/cursor'

import { Button } from 'components'
import { pages } from 'pages'

const Contact: React.FC = () => {
  const initialState: ContactUs = {
    name: '',
    email: '',
    subject: '',
    message: '',
    created_at: new Date()
  }

  const [contact, setContact] = useState<ContactUs>(initialState)
  const [submit, setSubmit] = useState<boolean>(false)
  const [send, setSend] = useState<boolean>(false)
  const [hide, setHide] = useState<boolean>(false)
  const [error, setError] = useState<boolean>(false)
  const [emailValid, setEmailValid] = useState<boolean>(true)

  const name = useRef<HTMLInputElement>(null)
  const email = useRef<HTMLInputElement>(null)
  const subject = useRef<HTMLInputElement>(null)
  const message = useRef<HTMLTextAreaElement>(null)

  const [step, setStep] = useState<number>(1)
  const step__count = 4
  const step__bar = 1 / step__count

  const page = useSelector((state: State) => state.page).page
  const active = page === pages.indexOf('/get-started') + 1

  const dispatch = useDispatch()

  const change = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setContact({ ...contact, [name]: value })
    setError(false)
  }

  const keydown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (step === step__count) save()
      else next()
    }
  }

  const back = () => {
    setHide(true)
    setTimeout(() => {
      setStep(step - 1)
      setHide(false)
    }, 500)
    setEmailValid(true)
    setError(false)
  }

  const next = () => {
    const fields = Object.keys(contact)
    // eslint-disable-next-line 
    const value = eval(`contact['${fields[step - 1]}']`)
    let valid = value
    if (fields[step - 1] === 'email' && !/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,}$/.test(value)) {
      valid = false
      if (value) setEmailValid(false)
      else setEmailValid(true)
    }
    if (valid) {
      setHide(true)
      setTimeout(() => {
        setStep(step + 1)
        setHide(false)
      }, 500)
      setEmailValid(true)
      setError(false)
    } else {
      setError(true)
    }
  }

  const save = () => {
    setSubmit(true)
    LoyalService.create({ ...contact })
      .then(() => {
        setSubmit(false)
        setSend(true)
        setHide(true)
        reset()
      })
      .catch(e => {
        setSubmit(false)
        setSend(false)
        setHide(false)
        console.log(e)
      })
  }

  const reset = () => {
    setContact(initialState)
    setSubmit(false)
  }

  useEffect(() => {
    name.current?.focus()
    const keyDown = (e: any) => {
      if ((e.code === 'Enter' || e.code === 'NumpadEnter') && step !== 4) next()
    }
    window.addEventListener('keydown', keyDown)
    return () => {
      window.removeEventListener('keydown', keyDown)
    }
  })

  useEffect(() => {
    const fields = Object.keys(contact)
    // eslint-disable-next-line
    setTimeout(() => eval(`${fields[step - 1]}.current?.focus()`), 100)
  }, [step, contact])

  const errors = [
    'Name field is required',
    'Email field is required',
    'Subject field is required',
    'Message field is required',
  ]
  const error__email = 'Please use a valid email address'

  return (
    <div className="loyal__app__project__plan">
      {active &&
        <>
          <div
            className={classnames(
              'loyal__app__project__plan__step__bar', { 'hide': send }
            )}
            style={{
              transform: `scaleX(${step__bar * step})`
            }}
          />
          <div className="loyal__app__project__plan__form">
            <input
              ref={name}
              className={classnames({ 'show': step === 1, hide })}
              type="text"
              name="name"
              placeholder="Your Name"
              value={contact.name}
              onChange={change}
              onKeyDown={keydown}
              onMouseOver={() => dispatch(text(true))}
              onMouseLeave={() => dispatch(text(false))}
              spellCheck="false"
              autoComplete="off"
              required
            />
            <input
              ref={email}
              className={classnames({ 'show': step === 2, hide })}
              type="email"
              name="email"
              placeholder="Email"
              value={contact.email}
              onChange={change}
              onKeyDown={keydown}
              onMouseOver={() => dispatch(text(true))}
              onMouseLeave={() => dispatch(text(false))}
              spellCheck="false"
              autoComplete="off"
              required
            />
            <input
              ref={subject}
              className={classnames({ 'show': step === 3, hide })}
              type="text"
              name="subject"
              placeholder="Subject"
              value={contact.subject}
              onChange={change}
              onKeyDown={keydown}
              onMouseOver={() => dispatch(text(true))}
              onMouseLeave={() => dispatch(text(false))}
              spellCheck="false"
              autoComplete="off"
              required
            />
            <div className={
              classnames(
                'description',
                {
                  'show': step === 4, hide
                }
              )}
            >
              <textarea
                ref={message}
                name="message"
                placeholder="How can we help?..."
                spellCheck="false"
                onChange={e => setContact({ ...contact, message: e.target.value })}
                onFocus={() => dispatch(setMove(true))}
                onBlur={() => dispatch(setMove(false))}
                onMouseOver={() => dispatch(text(true))}
                onMouseLeave={() => dispatch(text(false))}
              />
            </div>
            <div
              className={classnames(
                'success', { 'show': send, 'hide': !send }
              )}
            >
              <span className="title">
                You Really Did it.
              </span>
              <div>We'll be in touch.</div>
            </div>
          </div>
          {error &&
            <div className="loyal__app__project__plan__error">
              {!emailValid ? error__email : errors[step - 1]}
            </div>
          }
          {!send &&
            <>
              <div className="loyal__app__project__plan__step">
                {step} <span>/ {step__count}</span>
              </div>
              {!submit &&
                <div
                  className={classnames('button__wrapper', { 'single': step === 1 })}
                >
                  {step > 1 &&
                    <Button
                      onClick={back}
                      label={['BACK']}
                    />
                  }
                  {step < step__count &&
                    <Button
                      onClick={next}
                      label={['NEXT']}
                    />
                  }
                  {step === step__count &&
                    <Button
                      onClick={save}
                      label={['SUBMIT']}
                    />
                  }
                </div>
              }
              <div
                className={classnames(
                  'button__wrapper__single', { 'show': submit }
                )}
              >
                <Button
                  label={['SENDING', 'WAIT']}
                />
              </div>
            </>
          }
        </>
      }
    </div>
  )
}

export default Contact
