import React from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { State } from 'stores'
import { setMenu } from 'stores/effects/page'

import { Image } from 'components'

const Topbar: React.FC = () => {
  const dispatch = useDispatch()
  const page = useSelector((state: State) => state.page)
  return (
    <div className="loyal__app__topbar">
      <Image src="loyalid-blue.svg" path="logo" height="30" />
      <div
        className={classnames(
          'loyal__app__topbar__menu',
          {
            'open': page.menu
          }
        )}
        onClick={() => dispatch(setMenu(!page.menu))}
      >
        <div className="loyal__app__topbar__menu__animate">
          <div className="loyal__app__topbar__menu__dot" />
          <div className="loyal__app__topbar__menu__dot" />
        </div>
        <div className="loyal__app__topbar__menu__dot" />
        <div className="loyal__app__topbar__menu__animate">
          <div className="loyal__app__topbar__menu__dot" />
          <div className="loyal__app__topbar__menu__dot" />
        </div>
      </div>
    </div>
  )
}

export default Topbar
