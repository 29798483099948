import {
  PageAction,
  SET_PAGE,
  SET_PAGE_TO,
  SET_SCROLL,
  SET_MENU,
  SET_MOVE,
  SET_WHEEL,
  SET_LOGO
} from 'stores/interfaces/page'
import { Page } from 'types'

const initialState: Page = {
  page: 1,
  pageTo: 1,
  scroll: false,
  menu: false,
  move: false,
  wheel: true,
  logo: 'white'
}

export const page = (
  state = initialState,
  action: PageAction
): Page => {
  switch (action.type) {
    case SET_PAGE:
      return {
        ...state,
        page: action.page
      }
    case SET_PAGE_TO:
      return {
        ...state,
        pageTo: action.pageTo
      }
    case SET_SCROLL:
      return {
        ...state,
        scroll: action.scroll
      }
    case SET_MENU:
      return {
        ...state,
        menu: action.menu
      }
    case SET_MOVE:
      return {
        ...state,
        move: action.move
      }
    case SET_WHEEL:
      return {
        ...state,
        wheel: action.wheel
      }
    case SET_LOGO:
      return {
        ...state,
        logo: action.logo
      }
    default:
      return state
  }
}
