import { Dispatch } from 'redux'

import { PageAction } from 'stores/interfaces/page'
import * as Page from 'stores/actions/page'

export const setPage = (page: number) => {
  return function (dispatch: Dispatch<PageAction>) {
    dispatch(Page.setPage(page))
  }
}

export const setPageTo = (pageTo: number) => {
  return function (dispatch: Dispatch<PageAction>) {
    dispatch(Page.setPageTo(pageTo))
  }
}

export const setScroll = (scroll: boolean) => {
  return function (dispatch: Dispatch<PageAction>) {
    dispatch(Page.setScroll(scroll))
  }
}

export const setMenu = (menu: boolean) => {
  return function (dispatch: Dispatch<PageAction>) {
    dispatch(Page.setMenu(menu))
  }
}

export const setMove = (move: boolean) => {
  return function (dispatch: Dispatch<PageAction>) {
    dispatch(Page.setMove(move))
  }
}

export const setWheel = (wheel: boolean) => {
  return function (dispatch: Dispatch<PageAction>) {
    dispatch(Page.setWheel(wheel))
  }
}

export const setLogo = (logo: string) => {
  return function (dispatch: Dispatch<PageAction>) {
    dispatch(Page.setLogo(logo))
  }
}
