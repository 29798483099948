import {
  PageAction,
  SET_PAGE,
  SET_PAGE_TO,
  SET_SCROLL,
  SET_MENU,
  SET_MOVE,
  SET_WHEEL,
  SET_LOGO
} from 'stores/interfaces/page'

export const setPage = (page: number): PageAction => ({
  type: SET_PAGE,
  page
})

export const setPageTo = (pageTo: number): PageAction => ({
  type: SET_PAGE_TO,
  pageTo
})

export const setScroll = (scroll: boolean): PageAction => ({
  type: SET_SCROLL,
  scroll
})

export const setMenu = (menu: boolean): PageAction => ({
  type: SET_MENU,
  menu
})

export const setMove = (move: boolean): PageAction => ({
  type: SET_MOVE,
  move
})

export const setWheel = (wheel: boolean): PageAction => ({
  type: SET_WHEEL,
  wheel
})

export const setLogo = (logo: string): PageAction => ({
  type: SET_LOGO,
  logo
})
