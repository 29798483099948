import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { State } from 'stores'
import { Sidebar, Topbar, Menu, Container, Cursor } from 'components'
import { Intro, IntroImage, About, Services, Products, Work, Contact } from 'pages'

const App: React.FC = () => {
  const page__title: string[] = ['LOYAL.ID', 'ABOUT', 'SERVICES', 'PRODUCTS', 'OUR WORK', 'GET STARTED']
  const [title, setTitle] = useState<string>(page__title[0])

  const state = useSelector((state: State) => state.page)
  const page = Number(state.page)
  const to = Number(state.pageTo)

  const titlePage = () => setTitle(page__title[to - 1])

  useEffect(() => {
    if (page !== to) titlePage()
  })

  return (
    <>
      <Cursor />
      <Sidebar title={title} />
      <Topbar />
      <Menu />
      <IntroImage />
      <Container>
        <Intro />
        <About />
        <Services />
        <Products />
        <Work />
        <Contact />
      </Container>
    </>
  )
}

export default App
