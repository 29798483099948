import React, { useRef, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useSpring, a } from '@react-spring/web'

import { State } from 'stores'
import classnames from 'classnames'

const Cursor: React.FC = () => {
  const pointer = useRef<HTMLDivElement>(null)
  const core = useRef<HTMLDivElement>(null)
  const tail = useRef<HTMLDivElement>(null)
  let timeout: NodeJS.Timeout

  const move = useSelector((state: State) => state.page).move
  const cursor = useSelector((state: State) => state.cursor)
  const active = cursor.active
  const text = cursor.text
  const radius = cursor.radius

  const mousemove = (e: MouseEvent) => {
    set.start({ xy: calc(e.pageX, e.pageY) })
    setCore.start({ xy: calc(e.pageX, e.pageY) })
    setTail.start({ xy: calc(e.pageX, e.pageY) })
    if (pointer.current) {
      pointer.current.classList.remove('point')
      clearTimeout(timeout)
      timeout = setTimeout(() => pointer.current?.classList.add('point'), 500)
    }
  }

  const [props, set] = useSpring(() => ({
    xy: [0, -100],
    config: {
      mass: 2,
      tension: 500,
      friction: 100
    }
  }))

  const [propsCore, setCore] = useSpring(() => ({
    xy: [0, -100],
    config: {
      mass: 1,
      tension: 520,
      friction: 100
    }
  }))

  const [propsTail, setTail] = useSpring(() => ({
    xy: [0, -100],
    config: {
      mass: 10,
      tension: 300,
      friction: 100
    }
  }))

  const calc = (x: number, y: number) => [
    x, y
  ]
  const transition = (x: number, y: number) => `translate3d(${x}px,${y}px,0)`

  useEffect(() => {
    window.addEventListener('mousemove', mousemove)
    return () => {
      window.removeEventListener('mousemove', mousemove)
    }
  })

  return (
    <>
      <a.div
        className={classnames(
          'loyal__app__cursor',
          {
            active, text, move
          }
        )}
        style={{
          transform: props.xy.to(transition)
        }}
      >
        <div
          ref={pointer}
          className="loyal__app__cursor__pointer"
          style={{
            transform: `translate(-50%, -50%) scale(${radius && active ? radius : 1})`
          }}
        />
      </a.div>
      <a.div
        className={classnames(
          'loyal__app__cursor',
          {
            active, text, move
          }
        )}
        style={{
          transform: propsCore.xy.to(transition)
        }}
      >
        <div
          ref={core}
          className="loyal__app__cursor__core"
        />
      </a.div>
      <a.div
        className={classnames(
          'loyal__app__cursor',
          {
            active, text, move
          }
        )}
        style={{
          transform: propsTail.xy.to(transition)
        }}
      >
        <div
          ref={tail}
          className="loyal__app__cursor__tail"
        />
      </a.div>
    </>
  )
}

export default Cursor
