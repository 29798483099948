export const CURSOR_ACTIVE = 'CURSOR_ACTIVE'
export const CURSOR_TEXT = 'CURSOR_TEXT'

interface setActive {
  type: typeof CURSOR_ACTIVE
  active: boolean
  radius: number
}

interface setText {
  type: typeof CURSOR_TEXT
  text: boolean
}

export type CursorAction = setActive | setText
