import React, { ReactNode, useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { State } from 'stores'
import { setPage, setPageTo, setMove, setScroll, setMenu } from 'stores/effects/page'
import { active, text } from 'stores/effects/cursor'

import { Logo, Button } from 'components'
import { pages } from 'pages'
import classNames from 'classnames'

interface Props {
  children: ReactNode[]
}

const Container: React.FC<Props> = ({ children }) => {
  const [before, setBefore] = useState<ReactNode>(null)
  const [current, setCurrent] = useState<ReactNode>(children[0])
  const [after, setAfter] = useState<ReactNode>(children[1])
  const [direction, setDirection] = useState<number>(0)
  const [navigation, setNavigation] = useState<boolean>(false)

  const history = useHistory()

  const inner = useRef<HTMLDivElement>(null)
  const content = useRef<HTMLDivElement>(null)

  const dispatch = useDispatch()
  const state = useSelector((state: State) => state.page)
  const page = Number(state.page)
  const to = Number(state.pageTo)
  const move = state.move || state.scroll
  const scrolled = state.scroll

  const menu = useSelector((state: State) => state.page).menu

  const listener = (e: WheelEvent) => {
    const direction__ = Math.sign(e.deltaY)
    const height = window.innerHeight
    const contentHeight = Number(content.current?.scrollHeight)
    let scroll =
      height === contentHeight
      || (content.current!.scrollTop === 0 && direction__ < 0)
      || (content.current!.scrollTop + content.current!.offsetHeight >= content.current!.scrollHeight && direction__ > 0)

    if ((content.current!.scrollTop > 0 && content.current!.scrollTop + content.current!.offsetHeight < content.current!.scrollHeight) && !scrolled) {
      dispatch(setScroll(true))
    } else if (scrolled && (content.current!.scrollTop === 0 || content.current!.scrollTop + content.current!.offsetHeight >= content.current!.scrollHeight)) setTimeout(() => dispatch(setScroll(false)), 500)

    setTimeout(() => {
      if (direction !== direction__ && !menu) {
        if (direction__ < 0) {
          if (!move && page > 1 && scroll) {
            dispatch(setPageTo(page - 1))
            history.push(pages[page - 2])
            reset()
          }
        }
        if (direction__ > 0) {
          if (!move && scroll && page < children.length) {
            dispatch(setPageTo(page + 1))
            history.push(pages[page])
            reset()
          }
        }
        dispatch(active(false))
        dispatch(text(false))
      }
    }, 50)
    if (scroll && !move) setDirection(direction__)
  }

  const scrollListener = () => {
    const width = window.innerWidth

    if (width < 600 && page > 1 && page < 5) {
      if (!move && content.current!.scrollTop + content.current!.offsetHeight + 100 >= content.current!.scrollHeight) {
        // dispatch(setPageTo(page + 1))
        // history.push(pages[page])
        // reset()
        setNavigation(true)
      } else {
        setNavigation(false)
      }
    } else {
      setNavigation(false)
    }
  }

  const back = () => {
    dispatch(setPageTo(page - 1))
    history.push(pages[page - 2])
    reset()
  }

  const next = () => {
    dispatch(setPageTo(page + 1))
    history.push(pages[page])
    reset()
  }

  const reset = () => {
    setNavigation(false)
    dispatch(setMove(true))
    setTimeout(() => {
      setDirection(0)
      dispatch(setMove(false))
    }, 2200)
  }

  const pageTo = () => {
    inner.current!.style.transition = 'all 1s cubic-bezier(1,0,.5,.8)'
    const current = to - 1
    if (to > page) {
      setAfter(children[current])
      inner.current!.style.transform = 'translateX(-200vw)'
    } else {
      setBefore(children[current])
      inner.current!.style.transform = 'translateX(0)'
    }
    dispatch(setMenu(false))
    setTimeout(() => {
      content.current!.scrollTop = 0
      setCurrent(children[current])
      setBefore(children[current - 1] || null)
      setAfter(children[current + 1] || null)
      inner.current!.style.transition = 'unset'
      inner.current!.style.transform = 'translateX(-100vw)'
      dispatch(setPage(to))
    }, 1100)
  }

  const resize = () => {
    inner.current!.style.transition = 'unset'
  }

  const popstate = () => {
    setTimeout(() => {
      if (history.location.pathname === '/contact-us')
        dispatch(setPageTo(pages.indexOf('/get-started') + 1))
      else
        dispatch(setPageTo(pages.indexOf(history.location.pathname) + 1))
    }, 500)
  }

  useEffect(() => {
    if (page !== to) pageTo()
  })

  useEffect(() => {
    if (pages.indexOf(history.location.pathname) === -1)
      history.push('/')
    else if (history.location.pathname === '/contact-us')
      dispatch(setPageTo(pages.indexOf('/get-started') + 1))
    else
      dispatch(setPageTo(pages.indexOf(history.location.pathname) + 1))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    window.addEventListener('wheel', listener)
    return () => {
      window.removeEventListener('wheel', listener)
    }
  })

  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize)
    }
  })

  useEffect(() => {
    window.addEventListener('popstate', popstate)
    return () => {
      window.removeEventListener('popstate', popstate)
    }
  })

  const enter = () => {
    dispatch(active(true))
  }

  const leave = () => {
    dispatch(active(false))
  }

  return (
    <div className="loyal__app__container">
      <div
        onClick={() => {
          dispatch(setPageTo(1))
          history.push(pages[0])
          leave()
        }}
        onMouseEnter={enter}
        onMouseMove={enter}
        onMouseLeave={leave}
      >
        <Logo />
      </div>
      <div
        ref={inner}
        className="loyal__app__inner"
      >
        <div className="loyal__app__page">
          {before}
        </div>
        <div
          ref={content}
          className="loyal__app__page"
          onScroll={scrollListener}
        >
          {current}
        </div>
        <div className="loyal__app__page">
          {after}
        </div>
      </div>
      <div
        className={classNames(
          'mobile__navigation', { 'show': navigation && page > 1 && page < 5 }
        )}
      >
        <Button
          onClick={back}
          label={['BACK']}
        />
        <Button
          onClick={next}
          label={['NEXT']}
        />
      </div>
    </div>
  )
}

export default Container
