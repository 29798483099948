export { default as Intro } from 'pages/intro'
export { default as IntroImage } from 'pages/intro/image'
export { default as About } from 'pages/about'
export { default as Services } from 'pages/services'
export { default as Products } from 'pages/products'
export { default as Work } from 'pages/work'
export { default as Contact } from 'pages/contact'
export { default as Admin } from 'pages/admin'
export { default as PrivacyPolicy } from 'pages/privacy-policy'

export const pages = [
  '/',
  '/about',
  '/services',
  '/products',
  '/our-work',
  '/get-started',
  '/contact-us',
  '/privacy-policy'
]
