import { Dispatch } from 'redux'

import { CursorAction } from 'stores/interfaces/cursor'
import { setActive, setText } from 'stores/actions/cursor'

export const active = (action: boolean, radius: number = 5) => {
  return function (dispatch: Dispatch<CursorAction>) {
    dispatch(setActive(action, radius))
  }
}

export const text = (action: boolean) => {
  return function (dispatch: Dispatch<CursorAction>) {
    dispatch(setText(action))
  }
}
