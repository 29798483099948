import React from 'react'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'

import { active } from 'stores/effects/cursor'

interface Props {
  onClick?: () => void,
  label?: string[]
  arrow?: string
}

const Button: React.FC<Props> = ({ onClick, label, arrow }) => {
  const dispatch = useDispatch()
  let letters: string[] = ['SCROLL', 'NEXT']

  if (label) letters = label

  const enter = () => {
    dispatch(active(true))
  }

  const leave = () => {
    dispatch(active(false))
  }

  return (
    <div
      className={
        classnames('loyal__app__button', {
          'switch': letters.length === 2,
          'scroll': letters.indexOf('SCROLL') !== -1
        })
      }
      onClick={() => {
        leave()
        onClick && onClick()
      }}
      onMouseEnter={enter}
      onMouseMove={enter}
      onMouseLeave={leave}
    >
      <div className="loyal__app__button__inner">
        {letters.map((items, index) =>
          <div
            key={index}
            className="loyal__app__button__word"
          >
            {items.split('').map((letter, index) =>
              <div
                key={index}
                className="loyal__app__button__letter"
                style={{
                  animationDelay: `${index * .05}s`
                }}
              >
                {letter}
              </div>
            )}
          </div>
        )
        }
      </div>
      {letters.indexOf('NEXT') !== -1 &&
        <div className="loyal__app__button__arrow right" />
      }
      {letters.indexOf('BACK') !== -1 &&
        <div className="loyal__app__button__arrow left" />
      }
      {arrow === 'bottom' &&
        <div className="loyal__app__button__arrow bottom" />
      }
    </div>
  )
}

export default Button
