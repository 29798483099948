import { CSSProperties, FC } from 'react'
import { useTicker } from './hooks'

const Clock: FC = () => {
  const date = useTicker();

  const getTimeStyles = () => {
    return {
      "--current-seconds": date.getSeconds(),
      "--current-minutes": date.getMinutes(),
      "--current-hours": date.getHours() % 12,
    } as CSSProperties
  };

  return (
    <div
      className="loyal__app__analog__clock"
    >
      <div className="loyal__app__analog__clock__body" style={getTimeStyles()}>
        <div className="hour" />
        <div className="minute" />
        <div className="seconds" />
      </div>
      <div className="pin" />
    </div>
  )
}

export default Clock
