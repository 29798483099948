import { firestore } from 'config'
import { ProjectPlan } from 'types'

const db = firestore.collection("/plan__a__project")

const getAll = () => {
  return db
}

const create = (data: ProjectPlan) => {
  return db.add(data)
}

const update = (id: string, value: ProjectPlan) => {
  return db.doc(id).update(value)
}

const remove = (id: string) => {
  return db.doc(id).delete()
}

const LoyalService = {
  getAll,
  create,
  update,
  remove
}

export default LoyalService
