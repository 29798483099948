import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useDispatch } from 'react-redux'
import classnames from 'classnames'

import { active } from 'stores/effects/cursor'


import { Content } from 'components'

import Plan from './plan'
import Contact from './contact'

const Project: React.FC = () => {
  const [location, setLocation] = useState<string>('')

  const history = useHistory()
  const _location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    setTimeout(() => setLocation(_location.pathname), 100)
  }, [_location])

  const enter = () => {
    dispatch(active(true))
  }

  const leave = () => {
    dispatch(active(false))
  }

  return (
    <Content background="#25054d">
      <div className="loyal__app__project">
        <div className="loyal__app__project__options">
          <div
            className="selector"
            style={{
              top: location !== '/contact-us' ? '.5rem' : '2.5rem'
            }}
          />
          <div>
            <div
              className={classnames(
                'option', { 'active': location === '/get-started' }
              )}
              onClick={() => {
                history.push('/get-started')
                leave()
              }}
              onMouseEnter={enter}
              onMouseMove={enter}
              onMouseLeave={leave}
            >
              Work With Us
            </div>
            <div
              className={classnames(
                'option', { 'active': location === '/contact-us' }
              )}
              onClick={() => {
                history.push('/contact-us')
                leave()
              }}
              onMouseEnter={enter}
              onMouseMove={enter}
              onMouseLeave={leave}
            >
              Say Hello
            </div>
          </div>
        </div>
        {location === '/get-started' ?
          <Plan />
          :
          <Contact />
        }
        {/* <Plan /> */}
      </div>
    </Content>
  )
}

export default Project
