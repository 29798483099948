import React from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import { State } from 'stores'
import { Content, Image } from 'components'

const Services: React.FC = () => {
  const page = useSelector((state: State) => state.page).page
  const active = page === 3
  return (
    <Content active={active}>
      <div className="loyal__app__services">
        <div
          className={classnames(
            'title',
            {
              'show': active
            }
          )}
        >
          <div>
            <h1>Services<span>.</span></h1>
          </div>
          <div>
            <p>No matter how big you are, public or private, and in what industries or sectors you do business, we can help you work smarter and reach your goals. Have a look at the services we offer, below. And let’s talk.</p>
          </div>
        </div>
        <div className={
          classnames(
            'content',
            {
              'show': active
            }
          )}
        >
          <div
            style={{
              animationDelay: '1s'
            }}
          >
            <div className="icon">
              <Image
                src="crm.png"
                height="200"
              />
            </div>
            <span>CRM, LOYALTY, AND REWARDS</span>
          </div>
          <div
            style={{
              animationDelay: '1.2s'
            }}
          >
            <div className="icon">
              <Image
                src="e-commerce.png"
                height="200"
              />
            </div>
            <span>E-COMMERCE</span>
          </div>
          <div
            style={{
              animationDelay: '1.4s'
            }}
          >
            <div className="icon">
              <Image
                src="digital-engagment.png"
                height="200"
              />
            </div>
            <span>DIGITAL ENGAGEMENT</span>
          </div>
          <div
            style={{
              animationDelay: '1.6s'
            }}
          >
            <div className="icon">
              <Image
                src="pipeline.png"
                height="200"
              />
            </div>
            <span>VISUAL PIPELINE</span>
          </div>
          <div
            style={{
              animationDelay: '1.8s'
            }}
          >
            <div className="icon">
              <Image
                src="interaction.png"
                height="200"
              />
            </div>
            <span>INTERACTION BETWEEN CUSTOMER AND COMPANY</span>
          </div>
          <div
            style={{
              animationDelay: '2s'
            }}
          >
            <div className="icon">
              <Image
                src="automation.png"
                height="200"
              />
            </div>
            <span>SALES AUTOMATION TO MAXIMIZE SALES FOR COMPANY</span>
          </div>
        </div>
      </div>
    </Content>
  )
}

export default Services
