export const SET_PAGE = 'SET_PAGE'
export const SET_PAGE_TO = 'SET_PAGE_TO'
export const SET_SCROLL = 'SET_SCROLL'
export const SET_MENU = 'SET_MENU'
export const SET_MOVE = 'SET_MOVE'
export const SET_WHEEL = 'SET_WHEEL'
export const SET_LOGO = 'SET_LOGO'

interface setPage {
  type: typeof SET_PAGE
  page: number
}

interface setPageTo {
  type: typeof SET_PAGE_TO
  pageTo: number
}

interface setScroll {
  type: typeof SET_SCROLL
  scroll: boolean
}

interface setMenu {
  type: typeof SET_MENU
  menu: boolean
}

interface setMove {
  type: typeof SET_MOVE
  move: boolean
}

interface setWheel {
  type: typeof SET_WHEEL
  wheel: boolean
}

interface setLogo {
  type: typeof SET_LOGO
  logo: string
}

export type PageAction =
  | setPage | setPageTo | setScroll
  | setMenu | setMove | setWheel | setLogo
