import React, { useState, useEffect, useRef } from 'react'
import { useSpring, a } from '@react-spring/web'

interface Props {
  src: string
  path?: string
  width?: string | number
  height?: string | number
  parallax?: boolean
}

const Img: React.FC<Props> = ({ src, path = 'images', width, height = 100, parallax }) => {
  const [currentWidth, setCurrentWidth] = useState<number>(0)
  const [currentHeight, setCurrentHeight] = useState<number>(0)
  const url = () => require(`assets/${path}/${src}`).default
  const ref = useRef<HTMLDivElement>(null)

  let image = new Image()

  useEffect(() => {
    image.src = url()
    image.onload = () => {
      const naturalWidth = image.naturalWidth
      const naturalHeight = image.naturalHeight
      const ratio = width ? Number(width) / naturalWidth : Number(height) / naturalHeight
      setCurrentHeight(naturalHeight * ratio)
      setCurrentWidth(naturalWidth * ratio)
    }
    return () => {
      image.onload = null
    }
  })

  const [props, set] = useSpring(() => ({
    xy: [0, 0],
    config: {
      mass: 10,
      tension: 550,
      friction: 140
    }
  }))

  const calc = (x: number, y: number) => [
    x - window.innerWidth / 2, y - window.innerHeight / 2
  ]
  const transition = (x: number, y: number) => `translate3d(${x / 30}px,${y / 30}px,0)`

  const move = (e: MouseEvent) => {
    if (parallax) set.start({ xy: calc(e.pageX, e.pageY) })
  }

  useEffect(() => {
    window.addEventListener('mousemove', move)
    return () => {
      window.removeEventListener('mousemove', move)
    }
  })

  return (
    <>
      <a.div
        ref={ref}
        className="loyal__app__image"
        style={{
          width: currentWidth,
          height: currentHeight,
          backgroundImage: `url(${url()})`,
          backgroundSize: 'cover',
          transform: props.xy.to(transition)
        }}
      />
    </>
  )
}

export default Img
